const getSubDomain = () => {
  const parts = window.location.hostname.split('.');
  const subdomain = parts.shift();
  return subdomain;
};

const getOEMConfig = () => {
  const config = {};
  // サブドメインごとに定義。
  // header_background_color: ヘッダーの背景色（必須）
  // header_text_color: ヘッダーの文字色（必須）、人型アイコン
  // inquiries_phone_number: 問い合わせ画面の連絡先電話番号
  // inquiries_phone_hour: 問い合わせ画面の対応時間
  // header_logo_image_path: ヘッダーロゴの画像（S3のパス）
  // login_logo_image_path: ログイン画面のロゴ画像（S3のパス）
  // login_background_image_path: ログイン画面の背景画像（S3のパス）
  // app_location_add_text: サイドメニュー ロケーション追加ボタンの表示名
  // app_location_add_url: サイドメニュー ロケーション追加ボタンのリンク先パス。設定しない場合は「ロケーション追加」ボタンが非表示となる。
  // side_menu_color: サイドメニューの色
  // api_host: バックエンドAPIのURL
  // menu_mode: メニューの表示モード。「sidebar」の場合、サイドメニューを表示する。「horizontal」の場合、サイドメニューを非表示にする。
  // is_instagram_post: Instagram投稿機能を有効にする場合はtrue、無効にする場合はfalseを設定する。無効にした場合は全てのロケーションで簡易接続と同じ画面が表示される。

  switch (getSubDomain()) {
    case 'localhost': // ローカル
      config.header_background_color = '#181818';
      // config.header_background_color = '#ffffff';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '03-5358-9956';
      config.inquiries_phone_hour = '平日10:00~17:00';
      config.inquiries_phone_hour_en = 'Weekday 10:00~17:00';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/local/partners/header_logo.png`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/local/partners/login_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/local/partners/login_background.jpg`;
      config.app_location_add_text = 'ロケーション追加';
      config.app_location_add_url = 'https://forms.gle/YBKkqENEwLoRb6RH7';
      config.side_menu_color = '#cc0099';
      config.contrast_color = '#fbebf7';
      config.login_text_color = '#ffffff';
      config.graph_color_main = 'rgb(204, 0, 153)';
      config.graph_color_sub = 'rgb(255, 199, 212)';
      config.api_host = 'https://localhost:8000/api/v1';
      // config.api_host = 'https://dev-api.optimize-business.com/api/v1';
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.is_instagram_post = true;
      config.is_send_sms = true;
      config.send_sms_details_pdf =
        'https://igmb-api-files.s3.ap-northeast-1.amazonaws.com/files/uploaded/local/review_collections/sms_terms.pdf';
      config.is_yplace = true;
      config.is_display_privacy_policy = true;
      config.root_host = 'http://localhost:3001';
      break;
    case 'dev': // 開発
      config.header_background_color = '#181818';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '03-5358-9956';
      config.inquiries_phone_hour = '平日10:00~17:00';
      config.inquiries_phone_hour_en = 'Weekday 10:00~17:00';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/development/partners/header_logo.png`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/development/partners/login_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/development/partners/login_background.jpg`;

      config.app_location_add_text = 'ロケーション追加';
      config.app_location_add_url = 'https://forms.gle/YBKkqENEwLoRb6RH7';
      config.side_menu_color = '#cc0099';
      config.contrast_color = '#fbebf7';
      config.login_text_color = '#ffffff';
      config.graph_color_main = 'rgb(204, 0, 153)';
      config.graph_color_sub = 'rgb(255, 199, 212)';
      config.api_host = 'https://dev-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_basic_display_api = true;
      config.is_instagram_post = false;
      config.is_send_sms = true;
      config.send_sms_details_pdf =
        'https://igmb-api-files.s3.ap-northeast-1.amazonaws.com/files/uploaded/local/review_collections/sms_terms.pdf';
      config.is_yplace = true;
      config.root_host = 'https://devcms.optimize-business.com';
      break;
    case 'oem-dev': // OEM開発
      config.header_background_color = '#ffffff';
      config.header_text_color = '#000000';
      config.is_hide_inquery = true;
      // config.inquiries_phone_number = '';
      // config.inquiries_phone_hour = '';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/development/partners/epark_header_logo.png`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/development/partners/epark_header_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/development/partners/login_background.jpg`;
      // config.app_location_add_text = '素敵なリンク';
      // config.app_location_add_url = 'https://www.bbs-grp.com/';
      config.side_menu_color = '#EE6611';
      config.contrast_color = '#ffd0b3';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(238, 102, 17)';
      config.graph_color_sub = 'rgb(255, 208, 179)';
      config.api_host = 'https://oem-dev-api.optimize-business.com/api/v1';
      // config.menu_mode = 'horizontal';
      config.is_line_notification = true;
      config.is_hide_contract = true;
      config.is_hide_top = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_basic_display_api = true;
      config.is_instagram_post = false;
      config.is_yplace = true;
      break;
    case 'stg': // ステージング
      config.header_background_color = '#181818';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '03-5358-9956';
      config.inquiries_phone_hour = '平日10:00~17:00';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/staging/partners/header_logo.png`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/staging/partners/login_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/staging/partners/login_background.jpg`;
      config.app_location_add_text = 'ロケーション追加';
      config.app_location_add_url = 'https://forms.gle/YBKkqENEwLoRb6RH7';
      config.side_menu_color = '#cc0099';
      config.contrast_color = '#fdecf0';
      config.login_text_color = '#ffffff';
      config.graph_color_main = 'rgb(204, 0, 153)';
      config.graph_color_sub = 'rgb(253, 236, 240)';
      config.api_host = 'https://stg-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_basic_display_api = true;
      config.is_instagram_post = true;
      config.is_send_sms = true;
      config.send_sms_details_pdf =
        'https://igmb-api-files.s3.ap-northeast-1.amazonaws.com/files/uploaded/development/review_collections/sms_terms.pdf';
      config.is_yplace = true;
      config.root_host = 'https://cms.optimize-business.com';
      break;
    case 'bbs': // 本番
      config.header_background_color = '#181818';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '03-5358-9956';
      config.inquiries_phone_hour = '平日10:00~17:00';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/header_logo.png`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/login_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/login_background.jpg`;
      config.app_location_add_text = 'ロケーション追加';
      config.app_location_add_url = 'https://forms.gle/YBKkqENEwLoRb6RH7';
      config.side_menu_color = '#cc0099';
      config.contrast_color = '#fdecf0';
      config.login_text_color = '#ffffff';
      config.graph_color_main = 'rgb(204, 0, 153)';
      config.graph_color_sub = 'rgb(253, 236, 240)';
      config.api_host = 'https://bbs-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_basic_display_api = true;
      // config.menu_mode = 'horizontal';
      config.is_instagram_post = false;
      config.is_send_sms = true;
      config.send_sms_details_pdf =
        'https://igmb-prod-api-files.s3.ap-northeast-1.amazonaws.com/files/uploaded/production/sms/SMS_bbs.pdf';
      config.is_display_privacy_policy = true;
      config.root_host = 'https://root.optimize-business.com';
      break;
    case 'neonboard': // 合同会社Lien
      config.header_background_color = '#3A9CDF';
      config.header_text_color = '#ffffff';
      // config.inquiries_phone_number = '';
      // config.inquiries_phone_hour = '';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/neonboard_header_logo.png`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/neonboard_login_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/neonboard_login_background.png`;
      // config.app_location_add_text = '契約の確認';
      // config.app_location_add_url = 'https://www.lien-biz.com/';
      config.side_menu_color = '#47A1DD';
      config.contrast_color = '#EAEEF3';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(71, 161, 221)';
      config.graph_color_sub = 'rgb(234, 238, 243)';
      config.api_host = 'https://neonboard-api.optimize-business.com/api/v1';
      config.menu_mode = 'sidebar';
      config.is_line_notification = true;
      config.is_instagram_post = false;
      config.is_send_sms = true;
      config.send_sms_details_pdf =
        'https://igmb-prod-api-files.s3.ap-northeast-1.amazonaws.com/files/uploaded/production/sms/SMS_neonboard.pdf';
      break;
    case 'adcamp': // 株式会社プラヴィダ
      config.header_background_color = '#f0fff0';
      config.header_text_color = '#000000';
      config.inquiries_phone_number = '052-684-6242';
      config.inquiries_phone_hour = '平日9時〜18時';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/adcamp_header_logo.png`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/adcamp_header_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/adcamp_login_background.jpg`;
      // config.app_location_add_text = '契約の確認';
      // config.app_location_add_url = 'https://www.lien-biz.com/';
      config.side_menu_color = '#66cdaa';
      config.contrast_color = '#f0fff0';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(102, 205, 170)';
      config.graph_color_sub = 'rgb(240, 255, 240)';
      config.api_host = 'https://adcamp-api.optimize-business.com/api/v1';
      config.menu_mode = 'sidebar';
      config.is_line_notification = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      break;
    case 'man': // 株式会社MSN
      config.header_background_color = '#000000';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '080-4900-1979';
      config.inquiries_phone_hour = '9:30〜18:30';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/man_header_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/man_header_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/man_login_background.png`;
      // config.app_location_add_text = '契約の確認';
      // config.app_location_add_url = 'https://www.lien-biz.com/';
      config.side_menu_color = '#00ffff';
      config.contrast_color = '#deffff';
      config.login_text_color = '#ffffff';
      config.graph_color_main = 'rgb(0, 255, 255)';
      config.graph_color_sub = 'rgb(222, 255, 255)';
      config.api_host = 'https://man-api.optimize-business.com/api/v1';
      config.menu_mode = 'sidebar';
      config.is_line_notification = true;
      config.is_instagram_post = false;
      break;
    case 'cofucoma': // 株式会社LL Innovation
      config.header_background_color = '#000000';
      config.header_text_color = '#ffffff';
      // config.inquiries_phone_number = '052-684-6361';
      config.inquiries_phone_hour = '10時〜17時';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/cofucoma_header_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/cofucoma_header_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/cofucoma_login_background.jpg`;
      // config.app_location_add_text = '契約の確認';
      // config.app_location_add_url = 'https://www.lien-biz.com/';
      config.side_menu_color = '#33ccff';
      config.contrast_color = '#bdedfc';
      config.login_text_color = '#ffffff';
      config.graph_color_main = 'rgb(153, 255, 153)';
      config.graph_color_sub = 'rgb(227, 252, 227)';
      config.api_host = 'https://cofucoma-api.optimize-business.com/api/v1';
      config.menu_mode = 'sidebar';
      config.is_line_notification = true;
      config.is_instagram_post = false;
      config.is_send_sms = true;
      config.send_sms_details_pdf =
        'https://igmb-prod-api-files.s3.ap-northeast-1.amazonaws.com/files/uploaded/production/sms/SMS_cofucoma.pdf';
      break;
    case 'righton': // 株式会社ライトオン
      config.header_background_color = '#ffffff';
      config.header_text_color = '#000000';
      config.inquiries_phone_number = '0800-111-5886';
      config.inquiries_phone_hour = '月曜日から金曜日 9時〜18時';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/righton_header_logo.png`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/righton_header_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/righton_login_background.png`;
      // config.app_location_add_text = '契約の確認';
      // config.app_location_add_url = 'https://www.lien-biz.com/';
      config.side_menu_color = '#D00B0B';
      config.contrast_color = '#ffb8b8';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(208, 11, 11)';
      config.graph_color_sub = 'rgb(255, 184, 184)';
      config.api_host = 'https://righton-api.optimize-business.com/api/v1';
      config.menu_mode = 'sidebar';
      config.is_line_notification = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      config.is_send_sms = true;
      config.send_sms_details_pdf =
        'https://igmb-prod-api-files.s3.ap-northeast-1.amazonaws.com/files/uploaded/production/sms/SMS_righton.pdf';
      break;
    case 'streams': // 株式会社BAZIO
      config.header_background_color = '#ebf3ff';
      config.header_text_color = '#000000';
      config.inquiries_phone_number = '03-6277-0222';
      config.inquiries_phone_hour = '10:00-18:00';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/streams_header_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/streams_header_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/streams_login_background.png`;
      // config.app_location_add_text = '契約の確認';
      // config.app_location_add_url = 'https://www.lien-biz.com/';
      config.side_menu_color = '#3498db';
      config.contrast_color = '#ebf3ff';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(52, 152, 219)';
      config.graph_color_sub = 'rgb(235, 243, 255)';
      config.api_host = 'https://streams-api.optimize-business.com/api/v1';
      config.menu_mode = 'sidebar';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.is_instagram_post = false;
      break;
    case 'manage-biz': // 株式会社Shock Tech
      config.header_background_color = '#25252c';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '090-2404-4366';
      config.inquiries_phone_hour = '平日10時〜19時　休日10時〜19時';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/manage-biz_header_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/manage-biz_header_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/manage-biz_login_background.png`;
      // config.app_location_add_text = '契約の確認';
      // config.app_location_add_url = 'https://www.lien-biz.com/';
      config.side_menu_color = '#41414a';
      config.contrast_color = '#ebebeb';
      config.login_text_color = '#ffffff';
      config.graph_color_main = 'rgb(65, 65, 74)';
      config.graph_color_sub = 'rgb(235, 235, 235)';
      config.api_host = 'https://manage-biz-api.optimize-business.com/api/v1';
      config.menu_mode = 'sidebar';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.is_instagram_post = false;
      break;
    case 'i-egg': // 株式会社EPARKグルメ
      config.header_background_color = '#ffffff';
      config.header_text_color = '#000000';
      config.is_hide_inquery = true;
      config.inquiries_phone_number = '03-6878-3049';
      config.inquiries_phone_hour = '平日10:00~18:00';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/epark_header_logo.png`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/epark_header_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/epark_login_background.jpg`;
      // config.app_location_add_text = '素敵なリンク';
      // config.app_location_add_url = 'https://www.bbs-grp.com/';
      config.side_menu_color = '#EE6611';
      config.contrast_color = '#ffd0b3';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(238, 102, 17)';
      config.graph_color_sub = 'rgb(255, 208, 179)';
      config.api_host = 'https://i-egg-api.optimize-business.com/api/v1';
      config.menu_mode = 'sidebar';
      config.is_line_notification = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      config.is_send_sms = true;
      config.send_sms_details_pdf =
        'https://igmb-prod-api-files.s3.ap-northeast-1.amazonaws.com/files/uploaded/production/sms/SMS_EPARK.pdf';
      break;
    case 'user': // 株式会社アシスト
      config.header_background_color = '#414141';
      config.header_text_color = '#ffffff';
      config.is_hide_inquery = true;
      config.inquiries_phone_number = '050-5433-5878';
      config.inquiries_phone_hour = '月曜〜金曜9:00〜18:00 (土日祝休業)';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/user_header_logo.png`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/user_login_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/user_login_background.png`;
      // config.app_location_add_text = '契約の確認';
      // config.app_location_add_url = 'https://www.lien-biz.com/';
      config.side_menu_color = '#5DB9A9';
      config.contrast_color = '#edfffc';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(93, 185, 169)';
      config.graph_color_sub = 'rgb(237, 255, 252)';
      config.api_host = 'https://user-api.optimize-business.com/api/v1';
      config.menu_mode = 'sidebar';
      // config.menu_mode = 'horizontal';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.is_instagram_post = false;
      config.is_send_sms = true;
      config.send_sms_details_pdf =
        'https://igmb-prod-api-files.s3.ap-northeast-1.amazonaws.com/files/uploaded/production/sms/SMS_assist.pdf';
      config.is_yplace = true;
      break;
    case 'miline-bms': // 株式会社ミライアゴーゴー
      config.header_background_color = '#333333';
      config.header_text_color = '#ffffff';
      // config.inquiries_phone_number = '';
      // config.inquiries_phone_hour = '';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/miline-bms_header_logo.png`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/miline-bms_login_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/miline-bms_login_background.png`;
      // config.app_location_add_text = '契約の確認';
      // config.app_location_add_url = 'https://www.lien-biz.com/';
      config.side_menu_color = '#ef7a00';
      config.contrast_color = '#ffc991';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(239, 122, 0)';
      config.graph_color_sub = 'rgb(255, 201, 145)';
      config.api_host = 'https://miline-bms-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.is_instagram_post = false;
      break;
    case 'bridge': // エコテクソリューション株式会社
      config.header_background_color = '#000000';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '0570-200-324';
      config.inquiries_phone_hour = '平日10:00～17:00　土日祝除く';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/bridge_header_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/bridge_login_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/bridge_login_background.png`;
      // config.app_location_add_text = '契約の確認';
      // config.app_location_add_url = 'https://www.lien-biz.com/';
      config.side_menu_color = '#ff9922';
      config.contrast_color = '#ffd099';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(255, 153, 34)';
      config.graph_color_sub = 'rgb(255, 208, 153)';
      config.api_host = 'https://bridge-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_hide_contract = true;
      // config.is_hide_top = true;
      // config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.app_manual_url = 'https://manual.optimize-business.com/hc/ja';
      config.is_instagram_post = false;
      break;
    case 'tunagoo': // 株式会社Goodbright.
      config.header_background_color = '#FFFF65';
      config.header_text_color = '#000000';
      // config.inquiries_phone_number = '03-5544-9755';
      config.inquiries_phone_hour =
        '平日10:00~18:00（土日祝日、年末年始12/30〜1/3除く）';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/tunagoo_header_logo.png`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/tunagoo_login_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/tunagoo_login_background.png`;
      // config.app_location_add_text = '契約の確認';
      // config.app_location_add_url = 'https://www.lien-biz.com/';
      config.side_menu_color = '#F4BB66';
      config.contrast_color = '#fff0d9';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(244, 187, 102)';
      config.graph_color_sub = 'rgb(255, 240, 217)';
      config.api_host = 'https://tunagoo-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.is_instagram_post = false;
      config.is_send_sms = true;
      config.send_sms_details_pdf =
        'https://igmb-prod-api-files.s3.ap-northeast-1.amazonaws.com/files/uploaded/production/sms/SMS_tunagoo.pdf';
      break;
    case 'manual-sample': // マニュアル作成用
      config.header_background_color = '#FFFFFF';
      config.header_text_color = '#000000';
      config.inquiries_phone_number = '00-0000-1111';
      config.inquiries_phone_hour = '平日10:00~18:00';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/staging/partners/manual-sample_header_logo.png`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/staging/partners/manual-sample_header_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/staging/partners/manual-sample_header_logo.png`;
      // config.app_location_add_text = '契約の確認';
      // config.app_location_add_url = 'https://www.lien-biz.com/';
      config.side_menu_color = '#000000';
      config.contrast_color = '#e8e8e8';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(0, 0, 0)';
      config.graph_color_sub = 'rgb(232, 232, 232)';
      config.api_host =
        'https://manual-sample-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.is_instagram_post = true;
      config.is_send_sms = true;
      config.send_sms_details_pdf =
        'https://igmb-api-files.s3.ap-northeast-1.amazonaws.com/files/uploaded/local/review_collections/sms_terms.pdf';
      break;
    case 'connectie': // Serina Group
      config.header_background_color = '#dcdcdc';
      config.header_text_color = '#000000';
      config.inquiries_phone_number = '03-5927-9401';
      config.inquiries_phone_hour = '土日祝日除く、平日10:00〜19:00';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/connectie_header_logo.png`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/connectie_header_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/connectie_login_background.jpg`;
      // config.app_location_add_text = '契約の確認';
      // config.app_location_add_url = 'https://www.lien-biz.com/';
      config.side_menu_color = '#00bfff';
      config.contrast_color = '#dbf6ff';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(204, 0, 153)';
      config.graph_color_sub = 'rgb(248, 204, 238)';
      config.api_host = 'https://connectie-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_instagram_post = false;
      break;
    case 'appdate': // 株式会社AppDate
      config.header_background_color = '#0000FF';
      config.header_text_color = '#FFFFFF';
      config.inquiries_phone_number = '0120-522-922';
      config.inquiries_phone_hour = '10:00〜17:00 (土日祝を除く)';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/appdate_login_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/appdate_login_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/appdate_login_background.png`;
      // config.app_location_add_text = '契約の確認';
      // config.app_location_add_url = 'https://www.lien-biz.com/';
      config.side_menu_color = '#EEFFFF';
      config.contrast_color = '#EEFFFF';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(0, 191, 255)';
      config.graph_color_sub = 'rgb(238, 255, 255)';
      config.api_host = 'https://appdate-api.optimize-business.com/api/v1';
      config.is_basic_display_api = true;
      config.is_instagram_post = false;
      break;
    case 'asistaplus': // 株式会社ライフアップ
      config.header_background_color = '#FFBD59';
      config.header_text_color = '#000000';
      config.inquiries_phone_number = '0800-300-9674';
      config.inquiries_phone_hour = '年末年始を除く全日　11:00～20:00';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/asistaplus_header_logo.png`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/asistaplus_login_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/asistaplus_login_background.png`;
      config.app_location_add_text = 'ロケーション申込';
      config.app_location_add_url =
        'https://docs.google.com/forms/d/1HO8uTQpqu0m1MO3SZfiVm4uQYpjlbcIJFFJq5lqahlA/edit';
      config.side_menu_color = '#FFBD59';
      config.contrast_color = '#ffebcc';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(255, 189, 89)';
      config.graph_color_sub = 'rgb(255, 235, 204)';
      config.api_host = 'https://asistaplus-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_instagram_post = false;
      config.is_send_sms = true;
      config.send_sms_details_pdf =
        'https://igmb-prod-api-files.s3.ap-northeast-1.amazonaws.com/files/uploaded/production/sms/SMS_asistaplus.pdf';
      break;
    case 'voitasu': // 株式会社オールトゥデイ
      config.header_background_color = '#181818';
      config.header_text_color = '#FFFFFF';
      config.inquiries_phone_number = '048-638-7035';
      config.inquiries_phone_hour = '9：00～18：00・・・土日祝日は休日';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/voitasu_header_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/voitasu_login_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/voitasu_login_background.png`;
      // config.app_location_add_text = 'ロケーション申込';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/1HO8uTQpqu0m1MO3SZfiVm4uQYpjlbcIJFFJq5lqahlA/edit';
      config.side_menu_color = '#bc2795';
      config.contrast_color = '#ffdbf6';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(188, 39, 149)';
      config.graph_color_sub = 'rgb(255, 219, 246)';
      config.api_host = 'https://voitasu-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.is_instagram_post = false;
      break;
    case 'rakurakutenpo-shukyaku': // 株式会社ジーズコンサルティング
      config.header_background_color = '#FFEBCC';
      config.header_text_color = '#000000';
      config.inquiries_phone_number = '03-6427-4812';
      config.inquiries_phone_hour = '10：00～19：00';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/rakurakutenpo-shukyaku_header_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/rakurakutenpo-shukyaku_header_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/rakurakutenpo-shukyaku_login_background.png`;
      // config.app_location_add_text = 'ロケーション申込';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/1HO8uTQpqu0m1MO3SZfiVm4uQYpjlbcIJFFJq5lqahlA/edit';
      config.side_menu_color = '#FFAFAF';
      config.contrast_color = '#fff5f5';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(255, 175, 175)';
      config.graph_color_sub = 'rgb(255, 245, 245)';
      config.api_host =
        'https://rakurakutenpo-shukyaku-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_instagram_post = false;
      break;
    case 'tgmsystem': // 株式会社ツリーフードパートナーズ
      config.header_background_color = '#000000';
      config.header_text_color = '#FFFFFF';
      // config.inquiries_phone_number = '03-6384-7121';
      // config.inquiries_phone_hour = '10：00～18：00';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/tgmsystem_header_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/tgmsystem_header_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/tgmsystem_login_background.png`;
      // config.app_location_add_text = 'ロケーション申込';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/1HO8uTQpqu0m1MO3SZfiVm4uQYpjlbcIJFFJq5lqahlA/edit';
      config.side_menu_color = '#008b8b';
      config.contrast_color = '#c9ffff';
      config.login_text_color = '#6e7d7d';
      config.graph_color_main = 'rgb(0, 139, 139)';
      config.graph_color_sub = 'rgb(201, 255, 255)';
      config.api_host = 'https://tgmsystem-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.is_instagram_post = false;
      break;
    case 'giftls': // T-ONE
      config.header_background_color = '#1658DC';
      config.header_text_color = '#FFFFFF';
      config.inquiries_phone_number = '092-600-1238';
      config.inquiries_phone_hour = '土日祝以外の9時～18時まで';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/giftls_header_logo.png`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/giftls_login_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/giftls_login_background.jpg`;
      // config.app_location_add_text = 'ロケーション申込';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/1HO8uTQpqu0m1MO3SZfiVm4uQYpjlbcIJFFJq5lqahlA/edit';
      config.side_menu_color = '#1658DC';
      config.contrast_color = '#dee9ff';
      config.login_text_color = '#6e7d7d';
      config.graph_color_main = 'rgb(22, 88, 220)';
      config.graph_color_sub = 'rgb(222, 233, 255)';
      config.api_host = 'https://giftls-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_instagram_post = false;
      config.app_manual_url = 'https://manual.optimize-business.com/hc/ja';
      break;
    case 'premium-biz': // ジャパンネット
      config.header_background_color = '#262626';
      config.header_text_color = '#FFFFFF';
      config.inquiries_phone_number = '052-728-0502';
      config.inquiries_phone_hour = 'サポート受付時間 平日(9:00〜18:00)';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/premium-biz_header_logo.png`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/premium-biz_login_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/premium-biz_login_background.png`;
      // config.app_location_add_text = 'ロケーション申込';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/1HO8uTQpqu0m1MO3SZfiVm4uQYpjlbcIJFFJq5lqahlA/edit';
      config.side_menu_color = '#773e0d';
      config.contrast_color = '#ffe8d4';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(119, 62, 13)';
      config.graph_color_sub = 'rgb(255, 232, 212)';
      config.api_host = 'https://premium-biz-api.optimize-business.com/api/v1';
      config.is_basic_display_api = true;
      config.is_line_notification = true;
      config.is_instagram_post = false;
      config.is_yplace = true;
      break;
    case 'client': // イートラスト株式会社
      config.header_background_color = '#e8e7e7';
      config.header_text_color = '#000000';
      config.inquiries_phone_number = '0120-650-701';
      config.inquiries_phone_hour = '10:00~19:00';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/client_login_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/client_login_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/client_login_background.png`;
      // config.app_location_add_text = 'ロケーション申込';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/1HO8uTQpqu0m1MO3SZfiVm4uQYpjlbcIJFFJq5lqahlA/edit';
      config.side_menu_color = '#c20763';
      config.contrast_color = '#ffd9ec';
      config.login_text_color = '#FFFFFF';
      config.graph_color_main = 'rgb(194, 7, 99)';
      config.graph_color_sub = 'rgb(255, 217, 236)';
      config.api_host = 'https://client-api.optimize-business.com/api/v1';
      config.is_basic_display_api = true;
      config.is_instagram_post = false;
      break;
    case 'fbiz': // 株式会社ファイズホールディングス
      config.header_background_color = '#414141';
      config.header_text_color = '#FFFFFF';
      config.inquiries_phone_number = '0120-343-052';
      config.inquiries_phone_hour = '9:00~18:00';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/fbiz_header_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/fbiz_login_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/fbiz_login_background.png`;
      // config.app_location_add_text = 'ロケーション申込';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/1HO8uTQpqu0m1MO3SZfiVm4uQYpjlbcIJFFJq5lqahlA/edit';
      config.side_menu_color = '#E99311';
      config.contrast_color = '#ffe3b8';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(233, 147, 17)';
      config.graph_color_sub = 'rgb(255, 227, 184)';
      config.api_host = 'https://fbiz-api.optimize-business.com/api/v1';
      config.is_basic_display_api = true;
      config.is_instagram_post = false;
      break;
    case 'yobicom': // 株式会社AEGIS
      config.header_background_color = '#000080';
      config.header_text_color = '#FFFFFF';
      config.inquiries_phone_number = '080-4295-4692';
      config.inquiries_phone_hour = '土日祝以外 10時〜18時';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/yobicom_login_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/yobicom_login_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/yobicom_login_background.png`;
      config.app_location_add_text = 'ロケーション申込';
      config.app_location_add_url =
        'https://docs.google.com/forms/d/e/1FAIpQLScVOXLtCuN8WZ95jhHvSnfXVsYF4ysTx8ecbUuJX9aF6j9J0A/viewform';
      config.side_menu_color = '#4160e1';
      config.contrast_color = '#dbe2ff';
      config.login_text_color = '#FFFFFF';
      config.graph_color_main = 'rgb(65, 96, 225)';
      config.graph_color_sub = 'rgb(219, 226, 255)';
      config.api_host = 'https://yobicom-api.optimize-business.com/api/v1';
      config.is_instagram_post = false;
      break;
    case 'htl': // 株式会社アシスト その2
      config.header_background_color = '#d8d8d8';
      config.header_text_color = '#000000';
      config.inquiries_phone_number = '050-5433-5878';
      config.inquiries_phone_hour = '';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/htl_login_logo.png`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/htl_login_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/htl_login_background.png`;
      // config.app_location_add_text = 'ロケーション申込';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/e/1FAIpQLScVOXLtCuN8WZ95jhHvSnfXVsYF4ysTx8ecbUuJX9aF6j9J0A/viewform';
      config.side_menu_color = '#8E3F61';
      config.contrast_color = '#f5dae5';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(142, 63, 97)';
      config.graph_color_sub = 'rgb(245, 218, 229)';
      config.api_host = 'https://htl-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.is_instagram_post = false;
      config.is_send_sms = true;
      config.send_sms_details_pdf =
        'https://igmb-prod-api-files.s3.ap-northeast-1.amazonaws.com/files/uploaded/production/sms/SMS_assist.pdf';
      config.is_yplace = true;
      break;
    case 'onefinity': // 株式会社ビジョン
      config.header_background_color = '#000000';
      config.header_text_color = '#FFFFFF';
      config.inquiries_phone_number = '0120-95-016';
      config.inquiries_phone_hour = '土日祝を除く平日：10:00〜19:00';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/onefinity_header_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/onefinity_login_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/onefinity_login_background.jpg`;
      // config.app_location_add_text = 'ロケーション申込';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/e/1FAIpQLScVOXLtCuN8WZ95jhHvSnfXVsYF4ysTx8ecbUuJX9aF6j9J0A/viewform';
      config.side_menu_color = '#f5a658';
      config.contrast_color = '#fcecdc';
      config.login_text_color = '#ffffff';
      config.graph_color_main = 'rgb(245, 166, 88)';
      config.graph_color_sub = 'rgb(252, 236, 220)';
      config.api_host = 'https://onefinity-api.optimize-business.com/api/v1';
      config.is_instagram_post = false;
      break;
    case 'keykit': // 株式会社K2
      config.header_background_color = '#000000';
      config.header_text_color = '#FFFFFF';
      config.inquiries_phone_number = '050-3631-9922';
      config.inquiries_phone_hour = '土日祝以外の10時〜18時まで';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/keykit_header_logo.png`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/keykit_login_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/keykit_login_background.png`;
      // config.app_location_add_text = 'ロケーション申込';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/e/1FAIpQLScVOXLtCuN8WZ95jhHvSnfXVsYF4ysTx8ecbUuJX9aF6j9J0A/viewform';
      config.side_menu_color = '#ff1493';
      config.contrast_color = '#f5dfeb';
      config.login_text_color = '#ffffff';
      config.graph_color_main = 'rgb(255, 20, 147)';
      config.graph_color_sub = 'rgb(245, 223, 235)';
      config.api_host = 'https://keykit-api.optimize-business.com/api/v1';
      config.is_instagram_post = false;
      break;
    case 'personal': // 株式会社Personal
      config.header_background_color = '#87cefa';
      config.header_text_color = '#000000';
      config.inquiries_phone_number = '050-3358-4320';
      config.inquiries_phone_hour = '"土日祝休業 サポート受付時間 9時〜18時"';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/personal_header_logo.png`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/personal_header_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/personal_login_background.png`;
      // config.app_location_add_text = 'ロケーション申込';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/e/1FAIpQLScVOXLtCuN8WZ95jhHvSnfXVsYF4ysTx8ecbUuJX9aF6j9J0A/viewform';
      config.side_menu_color = '#a9a9a9';
      config.contrast_color = '#e6e3e3';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(169, 169, 169)';
      config.graph_color_sub = 'rgb(230, 227, 227)';
      config.api_host = 'https://personal-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.is_instagram_post = false;
      break;
    case '8biz': // 株式会社eighth tool
      config.header_background_color = '#bcd400';
      config.header_text_color = '#000000';
      config.inquiries_phone_number = '03-4236-8880';
      config.inquiries_phone_hour = '9時～19時';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/8biz_header_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/8biz_header_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/8biz_login_background.png`;
      // config.app_location_add_text = 'ロケーション申込';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/e/1FAIpQLScVOXLtCuN8WZ95jhHvSnfXVsYF4ysTx8ecbUuJX9aF6j9J0A/viewform';
      config.side_menu_color = '#00a33e';
      config.contrast_color = '#c3f7d7';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(0, 163, 62)';
      config.graph_color_sub = 'rgb(195, 247, 215)';
      config.api_host = 'https://8biz-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.is_instagram_post = false;
      break;
    case 'localizer': // 株式会社ガーディアン
      config.header_background_color = '#000000';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '03-4236-8880';
      config.inquiries_phone_hour = '9時～19時';
      config.is_hide_inquery = true;
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/localizer_header_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/localizer_login_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/localizer_login_background.png`;
      // config.app_location_add_text = 'ロケーション申込';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/e/1FAIpQLScVOXLtCuN8WZ95jhHvSnfXVsYF4ysTx8ecbUuJX9aF6j9J0A/viewform';
      config.side_menu_color = '#7e57c2';
      config.contrast_color = '#deccfc';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(126, 87, 194)';
      config.graph_color_sub = 'rgb(222, 204, 252)';
      config.api_host = 'https://localizer-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.is_instagram_post = false;
      break;
    case 'insta-mp': // 株式会社アールエム
      config.header_background_color = '#ffffff';
      config.header_text_color = '#000000';
      config.inquiries_phone_number = '0120-123-007';
      config.inquiries_phone_hour = '10:00～19:00（土日祝休）';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/insta-mp_header_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/insta-mp_header_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/insta-mp_login_background.png`;
      // config.app_location_add_text = 'ロケーション申込';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/e/1FAIpQLScVOXLtCuN8WZ95jhHvSnfXVsYF4ysTx8ecbUuJX9aF6j9J0A/viewform';
      config.side_menu_color = '#2467b5';
      config.contrast_color = '#b7d5f7';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(36, 103, 181)';
      config.graph_color_sub = 'rgb(183, 213, 247)';
      config.api_host = 'https://insta-mp-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.is_instagram_post = false;
      break;
    case 'cglink': // 株式会社コムラス
      config.header_background_color = '#000000';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '0952-97-8497';
      config.inquiries_phone_hour = '土日祝以外の10時〜17時まで';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/cglink_header_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/cglink_login_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/cglink_login_background.png`;
      // config.app_location_add_text = 'ロケーション申込';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/e/1FAIpQLScVOXLtCuN8WZ95jhHvSnfXVsYF4ysTx8ecbUuJX9aF6j9J0A/viewform';
      config.side_menu_color = '#00B1FF';
      config.contrast_color = '#cff0ff';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(0, 177, 255)';
      config.graph_color_sub = 'rgb(207, 240, 255)';
      config.api_host = 'https://cglink-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_instagram_post = false;
      break;
    case 'r': // 株式会社Uluru
      config.header_background_color = '#ffd700';
      config.header_text_color = '#000000';
      config.inquiries_phone_number = '03-6806-6538';
      config.inquiries_phone_hour = '';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/r_header_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/r_login_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/r_login_background.png`;
      config.app_location_add_text = 'ロケーション申込';
      config.app_location_add_url = 'https://forms.gle/UfD34PWgXerqL9bW9';
      config.side_menu_color = '#000000';
      config.contrast_color = '#e3e1e1';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(0, 0, 0)';
      config.graph_color_sub = 'rgb(227, 225, 225)';
      config.api_host = 'https://r-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_instagram_post = false;
      break;
    case 'sk': // 株式会社SK通信
      config.header_background_color = '#7195bb';
      config.header_text_color = '#000000';
      config.inquiries_phone_number = '050-5810-2740';
      config.inquiries_phone_hour = '9:00-18:00(土日祝休)';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/sk_header_logo.png`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/sk_login_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/sk_login_background.png`;
      // config.app_location_add_text = 'ロケーション申込';
      // config.app_location_add_url = 'https://forms.gle/UfD34PWgXerqL9bW9';
      config.side_menu_color = '#2b66b1';
      config.contrast_color = '#c9e1ff';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(43, 102, 177)';
      config.graph_color_sub = 'rgb(201, 225, 255)';
      config.api_host = 'https://sk-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.is_instagram_post = false;
      config.is_send_sms = true;
      config.send_sms_details_pdf =
        'https://igmb-prod-api-files.s3.ap-northeast-1.amazonaws.com/files/uploaded/production/sms/SMS_assist.pdf';
      config.is_yplace = true;
      break;
    case 'rg': // 株式会社ライジング
      config.header_background_color = '#220000';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '0120-977-310';
      config.inquiries_phone_hour = '月〜金　9:00〜18:00';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/rg_login_logo.png`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/rg_login_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/rg_login_background.png`;
      // config.app_location_add_text = 'ロケーション申込';
      // config.app_location_add_url = 'https://forms.gle/UfD34PWgXerqL9bW9';
      config.side_menu_color = '#FF773E';
      config.contrast_color = '#ffe0d4';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(255, 119, 62)';
      config.graph_color_sub = 'rgb(255, 224, 212)';
      config.api_host = 'https://rg-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.is_instagram_post = false;
      config.is_send_sms = true;
      config.send_sms_details_pdf =
        'https://igmb-prod-api-files.s3.ap-northeast-1.amazonaws.com/files/uploaded/production/sms/SMS_assist.pdf';
      config.is_yplace = true;
      break;
    case 'fr': // 株式会社フロンティア
      config.header_background_color = '#000000';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '0120-569-257';
      config.inquiries_phone_hour = '月〜金　9:00〜18:00';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/fr_header_logo.png`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/fr_login_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/fr_login_background.png`;
      // config.app_location_add_text = 'ロケーション申込';
      // config.app_location_add_url = 'https://forms.gle/UfD34PWgXerqL9bW9';
      config.side_menu_color = '#578bad';
      config.contrast_color = '#d9f0ff';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(87, 139, 173)';
      config.graph_color_sub = 'rgb(217, 240, 255)';
      config.api_host = 'https://fr-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.is_instagram_post = false;
      config.is_send_sms = true;
      config.send_sms_details_pdf =
        'https://igmb-prod-api-files.s3.ap-northeast-1.amazonaws.com/files/uploaded/production/sms/SMS_assist.pdf';
      config.is_yplace = true;
      break;
    case 'bos': // BOS株式会社
      config.header_background_color = '#ffffff';
      config.header_text_color = '#000000';
      config.inquiries_phone_number = '0120 712 264';
      config.inquiries_phone_hour = '土日祝以外の10時〜19時まで';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/bos_login_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/bos_login_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/bos_login_background.jpeg`;
      // config.app_location_add_text = 'ロケーション申込';
      // config.app_location_add_url = 'https://forms.gle/UfD34PWgXerqL9bW9';
      config.side_menu_color = '#04bcec';
      config.contrast_color = '#ccf4ff';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(4, 188, 236)';
      config.graph_color_sub = 'rgb(204, 244, 255)';
      config.api_host = 'https://bos-api.optimize-business.com/api/v1';
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_line_notification = true;
      config.is_instagram_post = false;
      break;
    case 'toridori': // 株式会社トリドリ
      config.header_background_color = '#0057b7';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '03-6892-3594';
      config.inquiries_phone_hour = '土日祝以外の10時〜18時まで';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/toridori_login_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/toridori_login_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/toridori_login_background.png`;
      config.app_location_add_text = 'ロケーション申込';
      config.app_location_add_url =
        'https://webforms.pipedrive.com/f/1I1IHLn3HDb7cOwuyZSI0hnAB8UwPh24RlsA7vDXomBMILoCtNPetc5MAmtLOqMNB';
      config.side_menu_color = '#0057b7';
      config.contrast_color = '#bddcff';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(0, 87, 183)';
      config.graph_color_sub = 'rgb(227, 237, 248)';
      config.api_host = 'https://toridori-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.is_instagram_post = false;
      break;
    case 'cg': // 株式会社CHANGE
      config.header_background_color = '#000000';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '0120-197-215';
      config.inquiries_phone_hour = '月〜金　9:00〜18:00';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/cg_login_logo.png`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/cg_login_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/cg_login_background.png`;
      // config.app_location_add_text = 'ロケーション申込';
      // config.app_location_add_url = 'https://webforms.pipedrive.com/f/1I1IHLn3HDb7cOwuyZSI0hnAB8UwPh24RlsA7vDXomBMILoCtNPetc5MAmtLOqMNB';
      config.side_menu_color = '#d96230';
      config.contrast_color = '#faccb9';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(217, 98, 48)';
      config.graph_color_sub = 'rgb(250, 204, 185)';
      config.api_host = 'https://cg-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.is_instagram_post = false;
      config.is_send_sms = true;
      config.send_sms_details_pdf =
        'https://igmb-prod-api-files.s3.ap-northeast-1.amazonaws.com/files/uploaded/production/sms/SMS_assist.pdf';
      config.is_yplace = true;
      break;
    case 'sail': // 株式会社Sail
      config.header_background_color = '#00bfff';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '050-5527-1120';
      config.inquiries_phone_hour = '平日10：00～18：00のみ受付';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/sail_header_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/sail_login_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/sail_login_background.png`;
      // config.app_location_add_text = 'ロケーション申込';
      // config.app_location_add_url = 'https://webforms.pipedrive.com/f/1I1IHLn3HDb7cOwuyZSI0hnAB8UwPh24RlsA7vDXomBMILoCtNPetc5MAmtLOqMNB';
      config.side_menu_color = '#0000ff';
      config.contrast_color = '#dcdcfc';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(0, 0, 255)';
      config.graph_color_sub = 'rgb(220, 220, 252)';
      config.api_host = 'https://sail-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.is_instagram_post = false;
      break;
    case 'tlm': // 株式会社TLマーケティング
      config.header_background_color = '#333333';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '0120-977-540';
      config.inquiries_phone_hour = '月〜金　9:00〜18:00';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/tlm_header_logo.png`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/tlm_login_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/tlm_login_background.png`;
      // config.app_location_add_text = 'ロケーション申込';
      // config.app_location_add_url = 'https://webforms.pipedrive.com/f/1I1IHLn3HDb7cOwuyZSI0hnAB8UwPh24RlsA7vDXomBMILoCtNPetc5MAmtLOqMNB';
      config.side_menu_color = '#669bca';
      config.contrast_color = '#cbe2f7';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(102, 155, 202)';
      config.graph_color_sub = 'rgb(203, 226, 247)';
      config.api_host = 'https://tlm-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.is_instagram_post = false;
      config.is_send_sms = true;
      config.send_sms_details_pdf =
        'https://igmb-prod-api-files.s3.ap-northeast-1.amazonaws.com/files/uploaded/production/sms/SMS_assist.pdf';
      config.is_yplace = true;
      break;
    case 'nln': // 株式会社NLNジャパン
      config.header_background_color = '#696969';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '03-5909-2020';
      config.inquiries_phone_hour =
        '10時～19時（月～金） ※祝日・年末年始・ゴールデンウィーク・お盆等大型連休を除く';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/nln_header_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/nln_login_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/nln_login_background.png`;
      // config.app_location_add_text = 'ロケーション申込';
      // config.app_location_add_url = 'https://webforms.pipedrive.com/f/1I1IHLn3HDb7cOwuyZSI0hnAB8UwPh24RlsA7vDXomBMILoCtNPetc5MAmtLOqMNB';
      config.side_menu_color = '#4169e1';
      config.contrast_color = '#9DAEE0';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(65, 105, 225)';
      config.graph_color_sub = 'rgb(157, 174, 224)';
      config.api_host = 'https://nln-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.is_instagram_post = false;
      break;
    case 'rl': // リアライズ
      config.header_background_color = '#000000';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '050-2018-1079';
      config.inquiries_phone_hour = '平日9:00〜18:00（土日祝休）';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/rl_header_logo.png`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/rl_login_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/rl_login_background.png`;
      // config.app_location_add_text = 'ロケーション申込';
      // config.app_location_add_url = 'https://webforms.pipedrive.com/f/1I1IHLn3HDb7cOwuyZSI0hnAB8UwPh24RlsA7vDXomBMILoCtNPetc5MAmtLOqMNB';
      config.side_menu_color = '#8ac7ce';
      config.contrast_color = '#d0e8eb';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(138, 199, 206)';
      config.graph_color_sub = 'rgb(208, 232, 235)';
      config.api_host = 'https://rl-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.is_instagram_post = false;
      config.is_send_sms = true;
      config.send_sms_details_pdf =
        'https://igmb-prod-api-files.s3.ap-northeast-1.amazonaws.com/files/uploaded/production/sms/SMS_assist.pdf';
      config.is_yplace = true;
      break;
    case 'kasoku': // スマミン
      config.header_background_color = '#e7372f';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '050-5527-3899';
      config.inquiries_phone_hour = '9:30〜18:30（土日祝休）';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/kasoku_header_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/kasoku_login_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/kasoku_login_background.png`;
      // config.app_location_add_text = 'ロケーション申込';
      // config.app_location_add_url = 'https://webforms.pipedrive.com/f/1I1IHLn3HDb7cOwuyZSI0hnAB8UwPh24RlsA7vDXomBMILoCtNPetc5MAmtLOqMNB';
      config.side_menu_color = '#e7372f';
      config.contrast_color = '#ffcccc';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(231, 55, 47)';
      config.graph_color_sub = 'rgb(255, 204, 204)';
      config.api_host = 'https://kasoku-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.is_instagram_post = false;
      break;
    case 'stoc': // STOC
      config.header_background_color = '#000080';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '0120-830-046';
      config.inquiries_phone_hour =
        '・サポート受付時間　9:00~18:00 月曜～金曜（平日のみ）';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/stoc_header_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/stoc_header_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/stoc_login_background.jpg`;
      // config.app_location_add_text = 'ロケーション申込';
      // config.app_location_add_url = 'https://webforms.pipedrive.com/f/1I1IHLn3HDb7cOwuyZSI0hnAB8UwPh24RlsA7vDXomBMILoCtNPetc5MAmtLOqMNB';
      config.side_menu_color = '#7d7d7d';
      config.contrast_color = '#f5f5f5';
      config.login_text_color = '#ffffff';
      config.graph_color_main = 'rgb(125, 125, 125)';
      config.graph_color_sub = 'rgb(245, 245, 245)';
      config.api_host = 'https://stoc-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.is_instagram_post = false;
      break;
    case 'webgrade': // レカムジャパン株式会社
      config.header_background_color = '#0000cd';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '06-4309-6418';
      config.inquiries_phone_hour =
        '・サポート受付時間　9:00~17:30 月曜～金曜（平日のみ）';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/webgrade_header_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/webgrade_header_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/webgrade_login_background.png`;
      // config.app_location_add_text = 'ロケーション申込';
      // config.app_location_add_url = 'https://webforms.pipedrive.com/f/1I1IHLn3HDb7cOwuyZSI0hnAB8UwPh24RlsA7vDXomBMILoCtNPetc5MAmtLOqMNB';
      config.side_menu_color = '#add8e6';
      config.contrast_color = '#edfbff';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(0, 0, 205)';
      config.graph_color_sub = 'rgb(237, 251, 255)';
      config.api_host = 'https://webgrade-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.is_hide_inquery = true;
      config.is_instagram_post = false;
      break;
    case 'stoc-light': // STOC
      config.header_background_color = '#000080';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '0120-830-046';
      config.inquiries_phone_hour =
        '・サポート受付時間　9:00~18:00 月曜～金曜（平日のみ）';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/stoc-light_header_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/stoc-light_header_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/stoc-light_login_background.jpg`;
      // config.app_location_add_text = 'ロケーション申込';
      // config.app_location_add_url = 'https://webforms.pipedrive.com/f/1I1IHLn3HDb7cOwuyZSI0hnAB8UwPh24RlsA7vDXomBMILoCtNPetc5MAmtLOqMNB';
      config.side_menu_color = '#757575';
      config.contrast_color = '#f5f5f5';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(117, 117, 117)';
      config.graph_color_sub = 'rgb(245, 245, 245)';
      config.api_host = 'https://stoc-light-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.is_instagram_post = false;
      break;
    case 'rs-support': // RSサポート
      config.header_background_color = '#DD0754';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '03-6822-7172';
      config.inquiries_phone_hour = '土日祝以外の10時〜18時まで';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/rs-support_login_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/rs-support_login_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/rs-support_login_background.png`;
      config.app_location_add_text = 'ロケーション申込';
      config.app_location_add_url =
        'https://docs.google.com/forms/d/e/1FAIpQLSeK_xwkEEry2_q7LNIIzvvUUwgIGA0nI7POBOtCska00oNBHA/viewform?usp=sf_link';
      config.side_menu_color = '#DD0754';
      config.contrast_color = '#ffedf4';
      config.login_text_color = '#ffffff';
      config.graph_color_main = 'rgb(221, 7, 84)';
      config.graph_color_sub = 'rgb(255, 237, 244)';
      config.api_host = 'https://rs-support-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_instagram_post = false;
      config.is_send_sms = true;
      config.send_sms_details_pdf =
        'https://igmb-prod-api-files.s3.ap-northeast-1.amazonaws.com/files/uploaded/production/sms/SMS_rs-support.pdf';
      break;
    case 'reright': // 株式会社ライトオン (アシスト商流)
      config.header_background_color = '#ffffff';
      config.header_text_color = '#000000';
      config.inquiries_phone_number = '0800-111-5886';
      config.inquiries_phone_hour = '月曜日から金曜日 9時〜18時';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/reright_login_logo.png`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/reright_login_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/reright_login_background.png`;
      // config.app_location_add_text = '契約の確認';
      // config.app_location_add_url = 'https://www.lien-biz.com/';
      config.side_menu_color = '#D00B0B';
      config.contrast_color = '#ffb8b8';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(208, 11, 11)';
      config.graph_color_sub = 'rgb(255, 184, 184)';
      config.api_host = 'https://reright-api.optimize-business.com/api/v1';
      config.menu_mode = 'sidebar';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.is_instagram_post = false;
      config.is_send_sms = true;
      config.send_sms_details_pdf =
        'https://igmb-prod-api-files.s3.ap-northeast-1.amazonaws.com/files/uploaded/production/sms/SMS_righton.pdf';
      config.is_yplace = true;
      break;
    case 'sgmeo': // 株式会社バックステージ
      config.header_background_color = '#BD1A21';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '03-3263-0219';
      config.inquiries_phone_hour = '月曜日から金曜日 9時〜18時';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/sgmeo_login_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/sgmeo_login_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/sgmeo_login_background.png`;
      // config.app_location_add_text = '契約の確認';
      // config.app_location_add_url = 'https://www.lien-biz.com/';
      config.side_menu_color = '#BD1A21';
      config.contrast_color = '#fcd2d3';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(189, 26, 33)';
      config.graph_color_sub = 'rgb(252, 210, 211)';
      config.api_host = 'https://sgmeo-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_hide_inquery = true;
      config.is_instagram_post = false;
      break;
    case 'gt': // 株式会社グッドタイム
      config.header_background_color = '#2666a4';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '03-6824-2417';
      config.inquiries_phone_hour = '月曜日から金曜日 9時〜18時';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/gt_login_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/gt_login_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/gt_login_background.png`;
      // config.app_location_add_text = '契約の確認';
      // config.app_location_add_url = 'https://www.lien-biz.com/';
      config.side_menu_color = '#2666a4';
      config.contrast_color = '#c4e2ff';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(38, 102, 164)';
      config.graph_color_sub = 'rgb(196, 226, 255)';
      config.api_host = 'https://gt-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_instagram_post = false;
      config.is_send_sms = true;
      config.send_sms_details_pdf =
        'https://igmb-prod-api-files.s3.ap-northeast-1.amazonaws.com/files/uploaded/production/sms/SMS_gt.pdf';
      config.is_yplace = true;
      break;
    case 'bridge-plus': // エコテク その2
      config.header_background_color = '#000000';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '0570-200-706';
      config.inquiries_phone_hour =
        '・サポート受付時間　平日10:00～17:00　土日祝除く';
      config.inquiries_notice1 =
        '※契約関係のお問合せはお電話にてお問合せください。';
      config.inquiries_notice2 =
        'システム関係のお問合せは基本的にお問合せフォームをご利用ください。';
      config.inquiries_notice3 =
        'お電話が込み合っている場合、すぐ出れない場合がございます。';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/bridge-plus_header_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/bridge-plus_login_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/bridge-plus_login_background.png`;
      // config.app_location_add_text = '契約の確認';
      // config.app_location_add_url = 'https://www.lien-biz.com/';
      config.side_menu_color = '#33b6c5';
      config.contrast_color = '#d4f8fc';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(51, 182, 197)';
      config.graph_color_sub = 'rgb(212, 248, 252)';
      config.api_host = 'https://bridge-plus-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_hide_contract = true;
      // config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.app_manual_url = 'https://manual.optimize-business.com/hc/ja';
      config.is_instagram_post = false;
      config.is_send_sms = true;
      config.send_sms_details_pdf =
        'https://igmb-prod-api-files.s3.ap-northeast-1.amazonaws.com/files/uploaded/production/sms/SMS_bridgeplus.pdf';
      break;
    case 'fullfill-google': // Fulfill株式会社 (ミカケル)
      config.header_background_color = '#777777';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '06-6136-7221';
      config.inquiries_phone_hour = '・サポート受付時間　9:00-18:00';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/fullfill-google_login_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/fullfill-google_login_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/fullfill-google_login_background.png`;
      // config.app_location_add_text = '契約の確認';
      // config.app_location_add_url = 'https://www.lien-biz.com/';
      config.side_menu_color = '#94c4eb';
      config.contrast_color = '#def0ff';
      config.login_text_color = '#FFFFFF';
      config.graph_color_main = 'rgb(148, 196, 235)';
      config.graph_color_sub = 'rgb(222, 240, 255)';
      config.api_host =
        'https://fullfill-google-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_instagram_post = false;
      break;
    case 'insta-mpplus': // アールエム
      config.header_background_color = '#ffffff';
      config.header_text_color = '#000000';
      config.inquiries_phone_number = '0120-123-007';
      config.inquiries_phone_hour =
        '・サポート受付時間　10:00～19:00（土日祝休）';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/insta-mpplus_login_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/insta-mpplus_login_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/insta-mpplus_login_background.png`;
      // config.app_location_add_text = '契約の確認';
      // config.app_location_add_url = 'https://www.lien-biz.com/';
      config.side_menu_color = '#2467b5';
      config.contrast_color = '#d1e6ff';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(36, 103, 181)';
      config.graph_color_sub = 'rgb(209, 230, 255)';
      config.api_host = 'https://insta-mpplus-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_instagram_post = false;
      break;
    case 'zero': // ZERONEXT
      config.header_background_color = '#e3ebf0';
      config.header_text_color = '#000000';
      config.inquiries_phone_number = '092-737-6262';
      config.inquiries_phone_hour =
        '・サポート受付時間　9:00～18:00 月～金(土日祝除く)';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/zero_login_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/zero_login_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/zero_login_background.jpg`;
      // config.app_location_add_text = '契約の確認';
      // config.app_location_add_url = 'https://www.lien-biz.com/';
      config.side_menu_color = '#c7ced3';
      config.contrast_color = '#ebeced';
      config.login_text_color = '#FFFFFF';
      config.graph_color_main = 'rgb(199, 206, 211)';
      config.graph_color_sub = 'rgb(235, 236, 237)';
      config.api_host = 'https://zero-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_instagram_post = false;
      config.is_yplace = true;
      break;
    case 'assist': // アシスト その3
      config.header_background_color = '#0a122e';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '050-5433-5878';
      config.inquiries_phone_hour = 'サポート受付時間　平日9:00～18:00';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/assist_login_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/assist_login_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/assist_login_background.png`;
      // config.app_location_add_text = '契約の確認';
      // config.app_location_add_url = 'https://www.lien-biz.com/';
      config.side_menu_color = '#4bc0e2';
      config.contrast_color = '#ccf4ff';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(75, 192, 226)';
      config.graph_color_sub = 'rgb(204, 244, 255)';
      config.api_host = 'https://assist-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      break;
    case 'sgmeo02': // SG
      config.header_background_color = '#BD1A21';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '03-3263-0219';
      config.inquiries_phone_hour = '・サポート受付時間　9:00～18:00';
      config.inquiries_notice1 = '・サポート受付曜日　月～金';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/sgmeo02_login_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/sgmeo02_login_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/sgmeo02_login_background.png`;
      // config.app_location_add_text = '契約の確認';
      // config.app_location_add_url = 'https://www.lien-biz.com/';
      config.side_menu_color = '#33b6c5';
      config.contrast_color = '#ed9a9e';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(189, 26, 33)';
      config.graph_color_sub = 'rgb(237, 154, 158)';
      config.api_host = 'https://sgmeo02-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      break;
    case 'sft': // 合同会社shift-it
      config.header_background_color = '#33CCFF';
      config.header_text_color = '#000000';
      config.inquiries_phone_number = '090-5994-1630';
      config.inquiries_phone_hour =
        '・サポート受付時間：10:00〜18:00まで（土日祝日含め）';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/sft_login_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/sft_login_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/sft_login_background.png`;
      config.app_location_add_text = '申し込みフォーム';
      config.app_location_add_url =
        'https://docs.google.com/forms/d/e/1FAIpQLSewVrN4zPOWInD8pdpdjYBKcjWOcZbkKtBqaoyDjJQlgnkQBQ/viewform?vc=0&c=0&w=1&flr=0';
      config.side_menu_color = '#FFF0F5';
      config.contrast_color = '#FFFFFF';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(255, 240, 245)';
      config.graph_color_sub = 'rgb(255, 255, 255)';
      config.api_host = 'https://sft-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      break;
    case 'gooconnect': // 株式会社FIRST COMMUNICATIONS
      config.header_background_color = '#38B2CC';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '0120-551-191';
      config.inquiries_phone_hour = '土日祝を除く10時〜19時まで';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/gooconnect_login_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/gooconnect_login_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/gooconnect_login_background.png`;
      // config.app_location_add_text = '申し込みフォーム';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/e/1FAIpQLSewVrN4zPOWInD8pdpdjYBKcjWOcZbkKtBqaoyDjJQlgnkQBQ/viewform?vc=0&c=0&w=1&flr=0';
      config.side_menu_color = '#38B2CC';
      config.contrast_color = '#d2f5fc';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(56, 178, 204)';
      config.graph_color_sub = 'rgb(210, 245, 252)';
      config.api_host = 'https://gooconnect-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      break;
    case 'meo-com': // 株式会社雄大ヒューマニティー
      config.header_background_color = '#2DC8FF';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '03-6380-5693';
      config.inquiries_phone_hour = '・サポート受付時間　平日　9時～17時30分';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/meo-com_login_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/meo-com_login_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/meo-com_login_background.png`;
      config.side_menu_color = '#2DC8FF';
      config.contrast_color = '#e3f8ff';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(45, 200, 255)';
      config.graph_color_sub = 'rgb(227, 248, 255)';
      config.api_host = 'https://meo-com-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      break;
    case 'grits': // 株式会社ｇｒｉｔｓ
      config.header_background_color = '#000000';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '06-4393-8493';
      config.inquiries_phone_hour =
        'サポート受付時間 9:00〜18:00　サポート受付曜日 平日：月曜〜金曜';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/grits_header_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/grits_header_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/grits_login_background.png`;
      // config.app_location_add_text = '申し込みフォーム';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/e/1FAIpQLSewVrN4zPOWInD8pdpdjYBKcjWOcZbkKtBqaoyDjJQlgnkQBQ/viewform?vc=0&c=0&w=1&flr=0';
      config.side_menu_color = '#40A5E3';
      config.contrast_color = '#c2e8ff';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(64, 165, 227)';
      config.graph_color_sub = 'rgb(194, 232, 255)';
      config.api_host = 'https://grits-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      break;
    case 'gourmet-uni': // 株式会社UNI‘SON
      config.header_background_color = '#FEAA00';
      config.header_text_color = '#000000';
      config.inquiries_phone_number = '080−5905−7335';
      config.inquiries_phone_hour = '土日祝以外の10時〜18時まで';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/gourmet-uni_header_logo.png`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/gourmet-uni_login_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/gourmet-uni_login_background.PNG`;
      config.app_location_add_text = '申し込みフォーム';
      config.app_location_add_url = 'https://forms.gle/hK5DB1ZrjBLXE7i3A';
      config.side_menu_color = '#FEAA00';
      config.contrast_color = '#FFDC96';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(254, 170, 0)';
      config.graph_color_sub = 'rgb(255, 220, 150)';
      config.api_host = 'https://gourmet-uni-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      break;
    case 'mefilas': // 株式会社マーケティングラボ
      config.header_background_color = '#000000';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '03-6681-3468';
      config.inquiries_phone_hour =
        '・サポート受付時間　11時から18時 月から金曜日（祝祭日除く）';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/mefilas_login_logo.png`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/mefilas_login_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/mefilas_login_background.png`;
      // config.app_location_add_text = '申し込みフォーム';
      // config.app_location_add_url = 'https://forms.gle/hK5DB1ZrjBLXE7i3A';
      config.side_menu_color = '#87cefa';
      config.contrast_color = '#dceefa';
      config.login_text_color = '#ffffff';
      config.graph_color_main = 'rgb(135, 206, 250)';
      config.graph_color_sub = 'rgb(220, 238, 250)';
      config.api_host = 'https://mefilas-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      break;
    case 'relier': // 株式会社Relier
      config.header_background_color = '#00bfff';
      config.header_text_color = '#000000';
      config.inquiries_phone_number = '080-3461-4787';
      config.inquiries_phone_hour =
        '・サポート受付時間：10:00-17:00（土日祝日を除く）';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/relier_login_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/relier_login_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/relier_login_background.jpg`;
      config.app_location_add_text = '申し込みフォーム';
      config.app_location_add_url =
        'https://docs.google.com/forms/d/1qokjv48_2nf3em_LY3ZeKunmqRbi4hR2zz605Unt2eM/viewform?pli=1&pli=1&edit_requested=true';
      config.side_menu_color = '#00bfff';
      config.contrast_color = '#ace8fc';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(0, 191, 255)';
      config.graph_color_sub = 'rgb(172, 232, 252)';
      config.api_host = 'https://relier-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      config.is_send_sms = true;
      config.send_sms_details_pdf =
        'https://igmb-prod-api-files.s3.ap-northeast-1.amazonaws.com/files/uploaded/production/sms/SMS_relier.pdf';
      break;
    case 'lead-connect': // 株式会社LEAD
      config.header_background_color = '#f23e2e';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '050-3176-2023';
      config.inquiries_phone_hour = '土日祝以外の10時〜18時まで）';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/lead-connect_header_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/lead-connect_login_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/lead-connect_login_background.png`;
      // config.app_location_add_text = '申し込みフォーム';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/1qokjv48_2nf3em_LY3ZeKunmqRbi4hR2zz605Unt2eM/viewform?pli=1&pli=1&edit_requested=true';
      config.side_menu_color = '#f23e2e';
      config.contrast_color = '#ffd7d4';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(242, 62, 46)';
      config.graph_color_sub = 'rgb(255, 215, 212)';
      config.api_host = 'https://lead-connect-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      break;
    case 'jljw': // 株式会社ジャストリンク
      config.header_background_color = '#304652';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '050-5433-5878';
      config.inquiries_phone_hour = '9:00~18:00 (月~金)';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/jljw_header_logo.png`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/jljw_header_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/jljw_login_background.png`;
      // config.app_location_add_text = '申し込みフォーム';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/1qokjv48_2nf3em_LY3ZeKunmqRbi4hR2zz605Unt2eM/viewform?pli=1&pli=1&edit_requested=true';
      config.side_menu_color = '#7cb4d0';
      config.contrast_color = '#e3f6ff';
      config.login_text_color = '#ffffff';
      config.graph_color_main = 'rgb(124, 180, 208)';
      config.graph_color_sub = 'rgb(227, 246, 255)';
      config.api_host = 'https://jljw-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      break;
    case 'meo': // 株式会社インターロック
      config.header_background_color = '#414141';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '0120-777-022';
      config.inquiries_phone_hour = '・サポート受付時間　9:00～18:00 (平日)';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/meo_header_logo.png`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/meo_login_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/meo_login_background.png`;
      // config.app_location_add_text = '申し込みフォーム';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/1qokjv48_2nf3em_LY3ZeKunmqRbi4hR2zz605Unt2eM/viewform?pli=1&pli=1&edit_requested=true';
      config.side_menu_color = '#cc0099';
      config.contrast_color = '#fbebf7';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(204, 0, 153)';
      config.graph_color_sub = 'rgb(255, 199, 212)';
      config.api_host = 'https://meo-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      break;
    case 'mikke': // 株式会社デジタル・コミュニケーションズ
      config.header_background_color = '#000000';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '03-6231-1855';
      config.inquiries_phone_hour = '・サポート受付時間　9:00～18:00 (平日)';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/mikke_header_logo.png`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/mikke_login_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/mikke_login_background.png`;
      // config.app_location_add_text = '申し込みフォーム';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/1qokjv48_2nf3em_LY3ZeKunmqRbi4hR2zz605Unt2eM/viewform?pli=1&pli=1&edit_requested=true';
      config.side_menu_color = '#b0e0e6';
      config.contrast_color = '#e1f7fa';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(176, 224, 230)';
      config.graph_color_sub = 'rgb(225, 247, 250)';
      config.api_host = 'https://mikke-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      config.is_yplace = true;
      break;
    case 'connect': // 株式会社シェア
      config.header_background_color = '#000000';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '0120-117-174';
      config.inquiries_phone_hour = '平日10：00～18：30';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/connect_header_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/connect_login_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/connect_login_background.png`;
      // config.app_location_add_text = '申し込みフォーム';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/1qokjv48_2nf3em_LY3ZeKunmqRbi4hR2zz605Unt2eM/viewform?pli=1&pli=1&edit_requested=true';
      config.side_menu_color = '#4ff7db';
      config.contrast_color = '#EDFFFC';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(79, 247, 219)';
      config.graph_color_sub = 'rgb(237, 255, 252)';
      config.api_host = 'https://connect-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      break;
    case 'vplan': // 一般社団法人全国VR推進協会
      config.header_background_color = '#467FF7';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '050-6861-1122';
      config.inquiries_phone_hour = 'サポート受付時間　10時〜18時 (平日)';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/vplan_header_logo.png`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/vplan_header_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/vplan_login_background.png`;
      // config.app_location_add_text = '申し込みフォーム';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/1qokjv48_2nf3em_LY3ZeKunmqRbi4hR2zz605Unt2eM/viewform?pli=1&pli=1&edit_requested=true';
      config.side_menu_color = '#467FF7';
      config.contrast_color = '#e0eaff';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(70, 127, 247)';
      config.graph_color_sub = 'rgb(224, 234, 255)';
      config.api_host = 'https://vplan-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      break;
    case 'snsbridge': // 株式会社ニューワークス
      config.header_background_color = '#ffffff';
      config.header_text_color = '#000000';
      config.inquiries_phone_number = '0120-754-741';
      // config.inquiries_phone_hour = '';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/snsbridge_header_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/snsbridge_header_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/snsbridge_login_background.png`;
      config.app_location_add_text = '申し込みフォーム';
      config.app_location_add_url =
        'https://docs.google.com/forms/d/1N-uicWpi_CYrkkxgMmEpJuHAKehyrG-kJdb_LpbtgP4/viewform?edit_requested=true';
      config.side_menu_color = '#000000';
      config.contrast_color = '#dbdbdb';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(0, 0, 0)';
      config.graph_color_sub = 'rgb(219, 219, 219)';
      config.api_host = 'https://snsbridge-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      break;
    case 'new-port': // スカイファーム株式会社
      config.header_background_color = '#383838';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '045-550-7179';
      config.inquiries_phone_hour = '平日10:00〜18:00';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/new-port_login_logo.png`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/new-port_login_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/new-port_login_background.png`;
      config.app_location_add_text = '申し込みフォーム';
      config.app_location_add_url =
        'https://docs.google.com/forms/d/e/1FAIpQLSd5XFqnuCSRSIHvYJKP1lOnIac2eUqMuO6mMT9776MleB1RMg/viewform';
      config.side_menu_color = '#000000';
      config.contrast_color = '#dbdbdb';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(0, 0, 0)';
      config.graph_color_sub = 'rgb(219, 219, 219)';
      config.api_host = 'https://new-port-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      break;
    case 'meonavi': // MEOナビ
      config.header_background_color = '#cbd5d9';
      config.header_text_color = '#000000';
      // config.inquiries_phone_number = '0120-55-4086';
      // config.inquiries_phone_hour = '平日10:00〜18:00';
      config.is_hide_inquery = true;
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/meonavi_header_logo.png`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/meonavi_header_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/meonavi_login_background.png`;
      // config.app_location_add_text = '申し込みフォーム';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/e/1FAIpQLSd5XFqnuCSRSIHvYJKP1lOnIac2eUqMuO6mMT9776MleB1RMg/viewform';
      config.side_menu_color = '#cbd5d9';
      config.contrast_color = '#e9f1f5';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(203, 213, 217)';
      config.graph_color_sub = 'rgb(233, 241, 245)';
      config.api_host = 'https://meonavi-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      break;
    case 'bizcon': // 株式会社エンジョイワークス
      config.header_background_color = '#F4E9D4';
      config.header_text_color = '#000000';
      config.inquiries_phone_number = '050-1790-6750';
      config.inquiries_phone_hour = '平日10:00〜18:00';
      // config.is_hide_inquery = true;
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/bizcon_login_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/bizcon_login_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/bizcon_login_background.png`;
      // config.app_location_add_text = '申し込みフォーム';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/e/1FAIpQLSd5XFqnuCSRSIHvYJKP1lOnIac2eUqMuO6mMT9776MleB1RMg/viewform';
      config.side_menu_color = '#000000';
      config.contrast_color = '#e6e6e6';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(0, 0, 0)';
      config.graph_color_sub = 'rgb(230, 230, 230)';
      config.api_host = 'https://bizcon-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      break;
    case 'peees': // 株式会社PEEES
      config.header_background_color = '#0f3382';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '03-6861-4630';
      config.inquiries_phone_hour = '平日10:00〜17:00';
      // config.is_hide_inquery = true;
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/peees_login_logo.png`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/peees_login_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/peees_login_background.png`;
      // config.app_location_add_text = '申し込みフォーム';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/e/1FAIpQLSd5XFqnuCSRSIHvYJKP1lOnIac2eUqMuO6mMT9776MleB1RMg/viewform';
      config.side_menu_color = '#d1c2c2';
      config.contrast_color = '#eeeeee';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(209, 194, 194)';
      config.graph_color_sub = 'rgb(238, 238, 238)';
      config.api_host = 'https://peees-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      config.is_send_sms = true;
      config.send_sms_details_pdf =
        'https://igmb-prod-api-files.s3.ap-northeast-1.amazonaws.com/files/uploaded/production/sms/SMS_peees.pdf';
      break;
    case 'wizbook': // 株式会社WIZ
      config.header_background_color = '#002858';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '050-1791-6292';
      config.inquiries_phone_hour = '平日9:00〜17:30';
      // config.is_hide_inquery = true;
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/wizbooks_login_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/wizbooks_login_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/wizbooks_login_background.png`;
      // config.app_location_add_text = '申し込みフォーム';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/e/1FAIpQLSd5XFqnuCSRSIHvYJKP1lOnIac2eUqMuO6mMT9776MleB1RMg/viewform';
      config.side_menu_color = '#185274';
      config.contrast_color = '#b3dbf2';
      config.login_text_color = '#ffffff';
      config.graph_color_main = 'rgb(24, 82, 116)';
      config.graph_color_sub = 'rgb(179, 219, 242)';
      config.api_host = 'https://wizbook-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = true;
      break;
    case 'l-connect': // 株式会社L O L
      config.header_background_color = '#000000';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '050-8880-5300';
      config.inquiries_phone_hour = '平日10:00〜18:00';
      // config.is_hide_inquery = true;
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/l-connect_header_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/l-connect_login_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/l-connect_login_background.png`;
      // config.app_location_add_text = '申し込みフォーム';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/e/1FAIpQLSd5XFqnuCSRSIHvYJKP1lOnIac2eUqMuO6mMT9776MleB1RMg/viewform';
      config.side_menu_color = '#000000';
      config.contrast_color = '#e8e8e8';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(0, 0, 0)';
      config.graph_color_sub = 'rgb(232, 232, 232)';
      config.api_host = 'https://l-connect-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      break;
    case 'fconnect': // エフコネクト株式会社
      config.header_background_color = '#65c2c2';
      config.header_text_color = '#000000';
      // config.inquiries_phone_number = '050-8880-5300';
      // config.inquiries_phone_hour = '平日10:00〜18:00';
      config.is_hide_inquery = true;
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/fconnect_login_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/fconnect_login_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/fconnect_login_background.png`;
      // config.app_location_add_text = '申し込みフォーム';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/e/1FAIpQLSd5XFqnuCSRSIHvYJKP1lOnIac2eUqMuO6mMT9776MleB1RMg/viewform';
      config.side_menu_color = '#65c2c2';
      config.contrast_color = '#cdfafa';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(101, 194, 194)';
      config.graph_color_sub = 'rgb(205, 250, 250)';
      config.api_host = 'https://fconnect-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      break;
    case 'khplus': // 株式会社ケーエイチプラス
      config.header_background_color = '#0000cd';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '050-5240-2401';
      config.inquiries_phone_hour = '平日10:00〜18:00';
      // config.is_hide_inquery = true;
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/khplus_login_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/khplus_login_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/khplus_login_background.png`;
      // config.app_location_add_text = '申し込みフォーム';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/e/1FAIpQLSd5XFqnuCSRSIHvYJKP1lOnIac2eUqMuO6mMT9776MleB1RMg/viewform';
      config.side_menu_color = '#00bfff';
      config.contrast_color = '#b1e9fc';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(0, 191, 255)';
      config.graph_color_sub = 'rgb(177, 233, 252)';
      config.api_host = 'https://khplus-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      break;
    case 'app': // 株式会社 AppDate Holdings
      config.header_background_color = '#0000ff';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '03-6419-7738';
      config.inquiries_phone_hour = '・平日10時～18時';
      // config.is_hide_inquery = true;
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/app_header_logo.png`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/app_login_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/app_login_background.png`;
      // config.app_location_add_text = '申し込みフォーム';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/e/1FAIpQLSd5XFqnuCSRSIHvYJKP1lOnIac2eUqMuO6mMT9776MleB1RMg/viewform';
      config.side_menu_color = '#0000ff';
      config.contrast_color = '#e1e1fa';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(0, 0, 255)';
      config.graph_color_sub = 'rgb(225, 225, 250)';
      config.api_host = 'https://app-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      break;
    case 'appp': // 株式会社 AppDate Holdings
      config.header_background_color = '#0000ff';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '03-6419-7738';
      config.inquiries_phone_hour = '・平日10時～18時';
      // config.is_hide_inquery = true;
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/appp_header_logo.png`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/appp_login_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/appp_login_background.png`;
      // config.app_location_add_text = '申し込みフォーム';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/e/1FAIpQLSd5XFqnuCSRSIHvYJKP1lOnIac2eUqMuO6mMT9776MleB1RMg/viewform';
      config.side_menu_color = '#0000ff';
      config.contrast_color = '#e1e1fa';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(0, 0, 255)';
      config.graph_color_sub = 'rgb(225, 225, 250)';
      config.api_host = 'https://appp-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      break;
    case 'simplebiz': // 株式会社サンリキュール
      config.header_background_color = '#ee82ee';
      config.header_text_color = '#000000';
      config.inquiries_phone_number = '0120-083-208';
      config.inquiries_phone_hour = '・平日9時～17時半';
      // config.is_hide_inquery = true;
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/simplebiz_login_logo.png`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/simplebiz_login_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/simplebiz_login_background.png`;
      // config.app_location_add_text = '申し込みフォーム';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/e/1FAIpQLSd5XFqnuCSRSIHvYJKP1lOnIac2eUqMuO6mMT9776MleB1RMg/viewform';
      config.side_menu_color = '#ee82ee';
      config.contrast_color = '#fcdefc';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(238, 130, 238)';
      config.graph_color_sub = 'rgb(252, 222, 252)';
      config.api_host = 'https://simplebiz-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      config.is_send_sms = true;
      config.send_sms_details_pdf =
        'https://igmb-prod-api-files.s3.ap-northeast-1.amazonaws.com/files/uploaded/production/sms/SMS_premiumbiz.pdf';
      break;
    case 'premiumbiz': // 株式会社サンリキュール
      config.header_background_color = '#00ff7f';
      config.header_text_color = '#000000';
      config.inquiries_phone_number = '0120-083-208';
      config.inquiries_phone_hour = '・平日9時～17時半';
      // config.is_hide_inquery = true;
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/premiumbiz_login_logo.png`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/premiumbiz_login_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/premiumbiz_login_background.png`;
      // config.app_location_add_text = '申し込みフォーム';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/e/1FAIpQLSd5XFqnuCSRSIHvYJKP1lOnIac2eUqMuO6mMT9776MleB1RMg/viewform';
      config.side_menu_color = '#00ff7f';
      config.contrast_color = '#b3fcd8';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(0, 255, 127)';
      config.graph_color_sub = 'rgb(179, 252, 216)';
      config.api_host = 'https://premiumbiz-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      config.is_send_sms = true;
      config.send_sms_details_pdf =
        'https://igmb-prod-api-files.s3.ap-northeast-1.amazonaws.com/files/uploaded/production/sms/SMS_simplebiz.pdf';
      break;
    case 'lilly': // 株式会社LillyHoldings
      config.header_background_color = '#DA4C40';
      config.header_text_color = '#000000';
      config.inquiries_phone_number = '052-502-0358';
      config.inquiries_phone_hour = '・平日9時～18時';
      // config.is_hide_inquery = true;
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/lilly_login_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/lilly_login_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/lilly_login_background.jpeg`;
      // config.app_location_add_text = '申し込みフォーム';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/e/1FAIpQLSd5XFqnuCSRSIHvYJKP1lOnIac2eUqMuO6mMT9776MleB1RMg/viewform';
      config.side_menu_color = '#384B66';
      config.contrast_color = '#dbeaff';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(56, 75, 102)';
      config.graph_color_sub = 'rgb(219, 234, 255)';
      config.api_host = 'https://lilly-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      break;
    case 'meo-n': // 株式会社NRC
      config.header_background_color = '#000000';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '0120-955-808';
      config.inquiries_phone_hour = '・平日9時～18時';
      // config.is_hide_inquery = true;
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/meo-n_login_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/meo-n_login_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/meo-n_login_background.png`;
      // config.app_location_add_text = '申し込みフォーム';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/e/1FAIpQLSd5XFqnuCSRSIHvYJKP1lOnIac2eUqMuO6mMT9776MleB1RMg/viewform';
      config.side_menu_color = '#0000cd';
      config.contrast_color = '#e1e1fc';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(0, 0, 205)';
      config.graph_color_sub = 'rgb(225, 225, 252)';
      config.api_host = 'https://meo-n-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      config.is_yplace = true;
      break;
    case 'a-step': // 株式会社アノテ
      config.header_background_color = '#d2b48c';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '06-6379-5515';
      config.inquiries_phone_hour = '・平日10時～18時';
      // config.is_hide_inquery = true;
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/a-step_login_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/a-step_login_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/a-step_login_background.png`;
      // config.app_location_add_text = '申し込みフォーム';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/e/1FAIpQLSd5XFqnuCSRSIHvYJKP1lOnIac2eUqMuO6mMT9776MleB1RMg/viewform';
      config.side_menu_color = '#FFDBC9';
      config.contrast_color = '#fff6db';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(252, 186, 3)';
      config.graph_color_sub = 'rgb(255, 246, 219)';
      config.api_host = 'https://a-step-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      break;
    case 'edge': // 株式会社エッジ
      config.header_background_color = '#007d80';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '050-8883-9274';
      config.inquiries_phone_hour = '・平日10時～18時';
      // config.is_hide_inquery = true;
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/edge_login_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/edge_login_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/edge_login_background.png`;
      // config.app_location_add_text = '申し込みフォーム';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/e/1FAIpQLSd5XFqnuCSRSIHvYJKP1lOnIac2eUqMuO6mMT9776MleB1RMg/viewform';
      config.side_menu_color = '#00301c';
      config.contrast_color = '#b1e6d0';
      config.login_text_color = '#ffffff';
      config.graph_color_main = 'rgb(0, 48, 28)';
      config.graph_color_sub = 'rgb(177, 230, 208)';
      config.api_host = 'https://edge-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      break;
    case 'link': // 株式会社エムエス企画
      config.header_background_color = '#1d4201';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '03-5809-3431';
      config.inquiries_phone_hour = '・平日12時～18時';
      // config.is_hide_inquery = true;
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/link_login_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/link_login_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/link_login_background.png`;
      // config.app_location_add_text = '申し込みフォーム';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/e/1FAIpQLSd5XFqnuCSRSIHvYJKP1lOnIac2eUqMuO6mMT9776MleB1RMg/viewform';
      config.side_menu_color = '#1d4201';
      config.contrast_color = '#e9fadc';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(29, 66, 1)';
      config.graph_color_sub = 'rgb(233, 250, 220)';
      config.api_host = 'https://link-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      break;
    case 'biz-meo': // 株式会社ライブノット
      config.header_background_color = '#002D59';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '03-3462-1810';
      config.inquiries_phone_hour =
        '・平日(土・日・祝日・夏季休暇・年末年始を除く) 10時～18時';
      // config.is_hide_inquery = true;
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/biz-meo_header_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/biz-meo_login_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/biz-meo_login_background.png`;
      // config.app_location_add_text = '申し込みフォーム';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/e/1FAIpQLSd5XFqnuCSRSIHvYJKP1lOnIac2eUqMuO6mMT9776MleB1RMg/viewform';
      config.side_menu_color = '#002D59';
      config.contrast_color = '#D4DCE6';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(0, 45, 89)';
      config.graph_color_sub = 'rgb(212, 220, 230)';
      config.api_host = 'https://biz-meo-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      config.is_send_sms = true;
      config.send_sms_details_pdf =
        'https://igmb-prod-api-files.s3.ap-northeast-1.amazonaws.com/files/uploaded/production/sms/SMS_biz-meo.pdf';
      config.is_yplace = true;
      break;
    case 'rapport': // bizy株式会社
      config.header_background_color = '#101e3c';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '050-1808-2044';
      config.inquiries_phone_hour = '・平日(土・日・祝日を除く) 10時～18時';
      // config.is_hide_inquery = true;
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/rapport_login_logo.png`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/rapport_login_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/rapport_login_background.png`;
      // config.app_location_add_text = '申し込みフォーム';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/e/1FAIpQLSd5XFqnuCSRSIHvYJKP1lOnIac2eUqMuO6mMT9776MleB1RMg/viewform';
      config.side_menu_color = '#101e3c';
      config.contrast_color = '#F3F4F6';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(16, 30, 60)';
      config.graph_color_sub = 'rgb(243, 244, 246)';
      config.api_host = 'https://rapport-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      break;
    case 'meoservice': // 株式会社バックステージ
      config.header_background_color = '#cbd5d9';
      config.header_text_color = '#000000';
      // config.inquiries_phone_number = '050-1808-2044';
      // config.inquiries_phone_hour = '・平日(土・日・祝日を除く) 10時～18時';
      config.is_hide_inquery = true;
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/meoservice_login_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/meoservice_login_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/meoservice_login_background.png`;
      // config.app_location_add_text = '申し込みフォーム';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/e/1FAIpQLSd5XFqnuCSRSIHvYJKP1lOnIac2eUqMuO6mMT9776MleB1RMg/viewform';
      config.side_menu_color = '#f0c0d0';
      config.contrast_color = '#fff0f5';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(240, 192, 208)';
      config.graph_color_sub = 'rgb(255, 240, 245)';
      config.api_host = 'https://meoservice-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      break;
    case 'g-bridge': // 株式会社グローバル・リンク・コミュニケーション
      config.header_background_color = '#002D49';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '092-737-0303';
      config.inquiries_phone_hour = '土日祝以外の10時〜18時まで';
      // config.is_hide_inquery = true;
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/g-bridge_login_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/g-bridge_login_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/g-bridge_login_background.png`;
      // config.app_location_add_text = '申し込みフォーム';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/e/1FAIpQLSd5XFqnuCSRSIHvYJKP1lOnIac2eUqMuO6mMT9776MleB1RMg/viewform';
      config.side_menu_color = '#DADBDB';
      config.contrast_color = '#d7e4fa';
      config.login_text_color = '#ffffff';
      config.graph_color_main = 'rgb(66, 135, 245)';
      config.graph_color_sub = 'rgb(215, 228, 250)';
      config.api_host = 'https://g-bridge-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      break;
    case 'sunliqurmeo': // 株式会社サンリキュール
      config.header_background_color = '#000080';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '0120-083-208';
      config.inquiries_phone_hour = '平日9:00-17:30';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/sunliqurmeo_login_logo.png`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/sunliqurmeo_login_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/sunliqurmeo_login_background.png`;
      // config.app_location_add_text = '申し込みフォーム';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/e/1FAIpQLSd5XFqnuCSRSIHvYJKP1lOnIac2eUqMuO6mMT9776MleB1RMg/viewform';
      config.side_menu_color = '#87cefa';
      config.contrast_color = '#dfe8f7';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(135, 206, 250)';
      config.graph_color_sub = 'rgb(223, 232, 247)';
      config.api_host = 'https://sunliqurmeo-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      config.is_send_sms = true;
      config.send_sms_details_pdf =
        'https://igmb-prod-api-files.s3.ap-northeast-1.amazonaws.com/files/uploaded/production/sms/SMS_sunliqurmeo.pdf';
      break;
    case 'ukn': // 株式会社UKN.
      config.header_background_color = '#0000ff';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '011-600-0690';
      config.inquiries_phone_hour = '平日10:00-18:00';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/ukn_login_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/ukn_login_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/ukn_login_background.png`;
      // config.app_location_add_text = '申し込みフォーム';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/e/1FAIpQLSd5XFqnuCSRSIHvYJKP1lOnIac2eUqMuO6mMT9776MleB1RMg/viewform';
      config.side_menu_color = '#00008b';
      config.contrast_color = '#e8ebfc';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(240, 100, 55)';
      config.graph_color_sub = 'rgb(232, 235, 252)';
      config.api_host = 'https://ukn-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      break;
    case 'mee-t': // 株式会社Meet
      config.header_background_color = '#1e90ff';
      config.header_text_color = '#000000';
      config.inquiries_phone_number = '03-5322-1340';
      config.inquiries_phone_hour = '・サポート受付時間 9:00～18:00 (月~金)';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/mee-t_login_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/mee-t_login_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/mee-t_login_background.png`;
      // config.app_location_add_text = '申し込みフォーム';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/e/1FAIpQLSd5XFqnuCSRSIHvYJKP1lOnIac2eUqMuO6mMT9776MleB1RMg/viewform';
      config.side_menu_color = '#0000ff';
      config.contrast_color = '#e4f7f5';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(0, 0, 255)';
      config.graph_color_sub = 'rgb(228, 247, 245)';
      config.api_host = 'https://mee-t-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      config.is_send_sms = true;
      config.send_sms_details_pdf =
        'https://igmb-prod-api-files.s3.ap-northeast-1.amazonaws.com/files/uploaded/production/sms/SMS_MEE_T.pdf';
      break;
    case 'deespot': // DeeSpot
      config.header_background_color = '#181818';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = 'xxxxxx';
      config.inquiries_phone_hour = '・サポート受付時間 xx~xx';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/deespot_header_logo.png`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/deespot_login_logo.png`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/deespot_login_background.png`;
      // config.app_location_add_text = '申し込みフォーム';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/e/1FAIpQLSd5XFqnuCSRSIHvYJKP1lOnIac2eUqMuO6mMT9776MleB1RMg/viewform';
      config.side_menu_color = '#cc0099';
      config.contrast_color = '#fdecf0';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(204, 0, 153)';
      config.graph_color_sub = 'rgb(253, 236, 240)';
      config.api_host = 'https://deespot-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      config.is_send_sms = false;
      break;
    case 'tsunagemap': // 株式会社フォネット
      config.header_background_color = '#00008b';
      config.header_text_color = '#ffffff';
      config.inquiries_phone_number = '055-223-6622';
      config.inquiries_phone_hour =
        '・サポート受付時間 10時〜17時 (土日祝を除く)';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/tsunagemap_login_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/tsunagemap_login_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/tsunagemap_login_background.jpg`;
      // config.app_location_add_text = '申し込みフォーム';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/e/1FAIpQLSd5XFqnuCSRSIHvYJKP1lOnIac2eUqMuO6mMT9776MleB1RMg/viewform';
      config.side_menu_color = '#dcdcdc';
      config.contrast_color = '#f5f5f5';
      config.login_text_color = '#000000';
      config.graph_color_main = 'rgb(220, 220, 220)';
      config.graph_color_sub = 'rgb(245, 245, 245))';
      config.api_host = 'https://tsunagemap-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      config.is_send_sms = false;
      break;
    case 'decentworks': // 株式会社ディーセントワークス
      config.header_background_color = '#00bfff';
      config.header_text_color = '#000000';
      config.inquiries_phone_number = '017-762-0587';
      config.inquiries_phone_hour =
        '・サポート受付時間 9時〜17時 (土日祝を除く)';
      config.header_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/decentworks_login_logo.svg`;
      config.login_logo_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/decentworks_login_logo.svg`;
      config.login_background_image_path = `${process.env.REACT_APP_AWS_S3_BASE_URL}/files/uploaded/production/partners/decentworks_login_background.png`;
      // config.app_location_add_text = '申し込みフォーム';
      // config.app_location_add_url = 'https://docs.google.com/forms/d/e/1FAIpQLSd5XFqnuCSRSIHvYJKP1lOnIac2eUqMuO6mMT9776MleB1RMg/viewform';
      config.side_menu_color = '#00bfff';
      config.contrast_color = '#d6f5ff';
      config.login_text_color = '#ffffff';
      config.graph_color_main = 'rgb(0, 191, 255)';
      config.graph_color_sub = 'rgb(214, 245, 255))';
      config.api_host = 'https://decentworks-api.optimize-business.com/api/v1';
      config.is_line_notification = true;
      config.is_basic_display_api = true;
      config.app_release_note_url = 'https://www.bbs-grp.com/releasenote';
      config.is_instagram_post = false;
      config.is_yplace = true;
      config.is_send_sms = true;
      config.send_sms_details_pdf =
        'https://igmb-prod-api-files.s3.ap-northeast-1.amazonaws.com/files/uploaded/production/sms/SMS_decentworks.pdf';
      break;
    default:
      break;
  }
  return config;
};

export default getOEMConfig;
