/* eslint-disable camelcase */

import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { CRow, CButton, CImg } from '@coreui/react';
import { useTranslation } from 'react-i18next';
import Loading from '../../../../commons/components/Loading';
import {
  REVIEW_COLLECTION_RATING_SUBMIT,
  CUSHION_REVIEW_GENERATE,
  REVIEW_CUSHION_TRANSLATE,
} from '../../../../commons/constants/url';
import StarRating from '../../../../commons/components/Rating';
import './cushion.scss';

function CushionView({
  previewLogo,
  title,
  description,
  keywords,
  is_preview,
  handleLogoChange,
  handleSubmit,
  handleReviewSubmitted,
  location_id,
  is_encrypted,
  business_category_name,
}) {
  const { t } = useTranslation(['gmb']);
  const { id } = useParams();
  const [isCopied, setIsCopied] = useState(false);
  const [rating, setRating] = useState(0);
  const [review_comment, setComment] = useState('');
  const [isRatingSelected, setIsRatingSelected] = useState(false);
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');


  const [translatedResponse, setTranslatedResponse] = useState({});

  const [loading, setLoading] = useState(true);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [imageURL, setImageURL] = useState('');
  const [btnText, setBtnText] = useState('');

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };
  const [showSvg, setShowSvg] = useState(false);
  useEffect(() => {
    const currentBrowserLanguage = navigator.language.split('-')[0];
    let defaultBrowserLang = selectedLanguage;

    if (selectedLanguage === '') {
      defaultBrowserLang = currentBrowserLanguage;
      setSelectedLanguage(defaultBrowserLang);
    }

    switch (defaultBrowserLang) {
      case 'en':
        setImageURL('https://igmb-api-files.s3.ap-northeast-1.amazonaws.com/files/uploaded/development/review_collections/EN.jpg');
        setBtnText('Post to Google in 3 easy steps!');
        break;
      case 'zh-CN':
        setImageURL('https://igmb-api-files.s3.ap-northeast-1.amazonaws.com/files/uploaded/development/review_collections/CH.jpg');
        setBtnText('只需 3 個簡單步驟即可發佈到 Google！');
        break;
      case 'ko':
        setImageURL('https://igmb-api-files.s3.ap-northeast-1.amazonaws.com/files/uploaded/development/review_collections/KO.jpg');
        setBtnText('Google에 쉽게 3 단계 게시!');
        break;
      case 'th':
        setImageURL('https://igmb-api-files.s3.ap-northeast-1.amazonaws.com/files/uploaded/development/review_collections/TH.jpg');
        setBtnText('โพสต์ไปที่ Google ใน 3 ขั้นตอนง่ายๆ!');
        break;
      case 'es':
        setImageURL('https://igmb-api-files.s3.ap-northeast-1.amazonaws.com/files/uploaded/development/review_collections/ES.jpg');
        setBtnText('¡Publica en Google en 3 sencillos pasos!');
        break;
      default:
        setImageURL('https://igmb-api-files.s3.ap-northeast-1.amazonaws.com/files/uploaded/development/review_collections/JP.jpg');
        setBtnText('Googleへ簡単3ステップ投稿！');
    }
  }, [selectedLanguage]);

  useEffect(() => {
    if (selectedLanguage) {
      setLoading(true);

      axios
        .get(`${REVIEW_CUSHION_TRANSLATE}`, {
          params: {
            target_language: selectedLanguage,
            title,
            location_id,
            description,
            step1_header: `Step 1: ${t('gmb:REVIEWS.CUSHION.SELECT_RATING')}`,
            step2_header: `Step 2: ${t(
              'gmb:REVIEWS.CUSHION.SELECT_KEYWORDS_MSG',
            )}`,
            step3_header: `Step 3: ${t('gmb:REVIEWS.CUSHION.FEEDBACK_MSG')}`,
            auto_generate_button_text: t(
              'gmb:REVIEWS.CUSHION.REVIEW_AUTO_CREATE',
            ),
            comment_placeholder: t('gmb:REVIEWS.CUSHION.COMMENT_OPTIONAL'),
            submit_button_text: t('gmb:REVIEWS.CUSHION.SUBMIT'),
            keywords,
          },
        })
        .then((response) => {
          setTranslatedResponse(response?.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [selectedLanguage]);

  const handleRatingsChange = (value) => {
    setRating(value);
    setIsRatingSelected(true);
  };

  const handleCommentChange = (event) => {
    const textarea = event.target;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
    setComment(event.target.value);
  };

  useEffect(() => {
    const textarea = document.querySelector('textarea');
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, [review_comment]);

  const handleSubmitRatings = () => {
    const payload = {
      rating,
      review_comment,
      is_encrypted,
      target: selectedLanguage,
      q: t('gmb:REVIEWS.CUSHION.CLIPBOARD_MESSAGE'),
    };

    axios
      .post(`${REVIEW_COLLECTION_RATING_SUBMIT}/${id}`, payload)
      .then((response) => {
        if (response?.data?.result?.redirect_url) {
          setRedirectUrl(response?.data?.result?.redirect_url);
          setShowSvg(true);
          if (!isCopied) {
            setTimeout(() => navigator.clipboard.writeText(review_comment));
            setIsCopied(true);
          }
          setTimeout(() => {
            setShowSvg(false);
            window.location.href = response?.data?.result?.redirect_url;
          }, 6000);
        } else {
          handleReviewSubmitted(true);
        }
      })
      .catch((error) => {
        console.error('Error submitting ratings:', error);
      });
  };

  const StarReview = ({
    value,
    filled,
    ratingChange,
  }) => {
    return (
      <span
        className="star"
        onClick={() => ratingChange(value)}
        // onMouseEnter={() => mouseEnter(value)}
        // onMouseLeave={mouseLeave}
        onKeyPress={(e) => {
          if (e.key === 'Enter' || e.key === ' ') ratingChange(value);
        }}
        role="button"
        tabIndex={0}
      >
        {filled ? '\u2605' : '\u2606'}
      </span>
    );
  };

  StarReview.propTypes = {
    value: PropTypes.number.isRequired,
    filled: PropTypes.bool.isRequired,
    ratingChange: PropTypes.func.isRequired,
  };

  const handleKeywordSelection = (keyword) => {
    if (selectedKeywords.includes(keyword)) {
      setSelectedKeywords(selectedKeywords.filter((k) => k !== keyword));
    } else {
      setSelectedKeywords([...selectedKeywords, keyword]);
    }
  };

  const handleGenerateCaption = () => {
    if (selectedKeywords.length === 0 || !isRatingSelected) {
      return;
    }
    setIsLoading(true);
    const payload = {
      keywords: selectedKeywords,
      location_id,
      rating,
      business_category_name,
      language: selectedLanguage,
    };
    axios
      .post(`${CUSHION_REVIEW_GENERATE}`, payload)
      .then((response) => {
        if (response.data.success) {
          const generatedReview = response.data.result.suggestReview;
          setComment(generatedReview);
        } else {
          console.error('Error generating review:', response.data.message);
        }
      })
      .catch((error) => {
        console.error('API request failed:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const shouldShowGenerateCaptionButton =
    !is_preview && Array.isArray(keywords) && keywords.length > 0;

  const handleDirectRedirect = () => {
    if (redirectUrl !== '') {
      window.location.href = redirectUrl;
    } else {
      handleReviewSubmitted(true);
    }

  };

  return (
    <>
      <Loading loading={isLoading} />
      {loading && !is_preview ? (
        <h5>Please wait a moment ...</h5>
      ) : (
        <>
          {
            showSvg ?
              (<>
                <div className="confirm-section">

                  <img
                    src={imageURL}
                    alt="Cushion_page_Instruction"
                  />

                  <CButton
                    type="submit"
                    className="confirm-btn"
                    // onClick={handleSubmitRatings}
                    // disabled={!isRatingSelected}
                    onClick={handleDirectRedirect}
                  >
                    {btnText}
                  </CButton>
                </div>

              </>) : (<>
                {previewLogo ? (
                  <CRow>
                    <CImg
                      className="selected-photo"
                      src={previewLogo}
                      alt="Logo Preview"
                    />
                  </CRow>
                ) : (
                  <CRow className="empty-image-container">
                    <div className="empty-image-section">
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleLogoChange}
                      />
                      <p>{t('gmb:REVIEWS.CUSHION.NO_IMAGE')}</p>
                    </div>
                  </CRow>
                )}

                {title ? (
                  <CRow>
                    <div className="preview-title">
                      <h2>
                        {translatedResponse && !is_preview
                          ? translatedResponse?.title
                          : title}
                      </h2>
                    </div>
                  </CRow>
                ) : (
                  <CRow className="empty-title-section">
                    <div className="empty-content">
                      {t('gmb:REVIEWS.CUSHION.TITLE')}
                    </div>
                  </CRow>
                )}
                {description ? (
                  <CRow>
                    <div className="preview-description">
                      <div className="des-box">
                        {translatedResponse && !is_preview
                          ? translatedResponse?.description
                          : description}
                      </div>
                    </div>
                  </CRow>
                ) : (
                  <CRow className="empty-description-section">
                    <div className="empty-content">
                      {t('gmb:REVIEWS.CUSHION.DESCRIPTION')}
                    </div>
                  </CRow>
                )}
                {is_preview ? (
                  <>
                    <div className="user-ratings">
                      <div className="keywords-text">
                        {translatedResponse && !is_preview ? (
                          <>
                            <span>{translatedResponse.step1_header}</span>
                          </>
                        ) : (
                          <span>Step1: {t('gmb:REVIEWS.CUSHION.SELECT_RATING')}</span>
                        )}
                      </div>
                      <div className="rate">
                        <StarRating className="star" value={0} />
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="user-ratings">
                    <div className="keywords-text">
                      {translatedResponse && !is_preview ? (
                        <>
                          <span>{translatedResponse.step1_header}</span>
                        </>
                      ) : (
                        <span>Step1: {t('gmb:REVIEWS.CUSHION.SELECT_RATING')}</span>
                      )}
                    </div>
                    <div className="rate">
                      {[1, 2, 3, 4, 5].map((value) => (
                        <StarReview
                          key={value}
                          value={value}
                          // filled={value <= hoverRating || value <= rating}
                          filled={value <= rating}
                          ratingChange={handleRatingsChange}
                        // mouseEnter={handleMouseEnter}
                        // mouseLeave={handleMouseLeave}
                        />
                      ))}
                    </div>
                  </div>
                )}
                {Array.isArray(keywords) && keywords.length > 0 ? (
                  <>
                    <div className="keywords-select">
                      <div className="keywords-text">
                        {translatedResponse && !is_preview ? (
                          <>
                            <span>{translatedResponse.step2_header}</span>
                          </>
                        ) : (
                          <span>
                            Step 2: {t('gmb:REVIEWS.CUSHION.SELECT_KEYWORDS_MSG')}
                          </span>
                        )}
                      </div>
                      <div className="preview-keywords">
                        <div className="keywords-tags">
                          {translatedResponse?.keywords && !is_preview
                            ? translatedResponse.keywords.map((keyword) => (
                              <span
                                className={`keyword-tag ${selectedKeywords.includes(keyword)
                                  ? 'selected'
                                  : ''
                                  } ${!is_preview && selectedKeywords.includes(keyword)
                                    ? 'text-white'
                                    : ''
                                  }`}
                                key={keyword}
                                onClick={() => {
                                  if (!is_preview) {
                                    handleKeywordSelection(keyword);
                                  }
                                }}
                                onKeyPress={(e) => {
                                  if (
                                    !is_preview &&
                                    (e.key === 'Enter' || e.key === ' ')
                                  ) {
                                    handleKeywordSelection(keyword);
                                  }
                                }}
                                role="button"
                                tabIndex={0}
                              >
                                {keyword}
                              </span>
                            ))
                            : keywords.map((keyword) => (
                              <span
                                className={`keyword-tag ${selectedKeywords.includes(keyword)
                                  ? 'selected'
                                  : ''
                                  } ${!is_preview && selectedKeywords.includes(keyword)
                                    ? 'text-white'
                                    : ''
                                  }`}
                                key={keyword}
                                onClick={() => {
                                  if (!is_preview) {
                                    handleKeywordSelection(keyword);
                                  }
                                }}
                                onKeyPress={(e) => {
                                  if (
                                    !is_preview &&
                                    (e.key === 'Enter' || e.key === ' ')
                                  ) {
                                    handleKeywordSelection(keyword);
                                  }
                                }}
                                role="button"
                                tabIndex={0}
                              >
                                {keyword}
                              </span>
                            ))}
                        </div>
                      </div>
                    </div>
                  </>
                ) : is_preview ? (
                  <div className="empty-keywords-section">
                    <div className="keywords-text">
                      {translatedResponse && !is_preview ? (
                        <>
                          <span>{translatedResponse.step2_header}</span>
                        </>
                      ) : (
                        <span>
                          Step 2: {t('gmb:REVIEWS.CUSHION.SELECT_KEYWORDS_MSG')}
                        </span>
                      )}
                    </div>
                    <div className="empty-content-keywords"> </div>
                  </div>
                ) : null}
                <div className="comment-box">
                  {(Array.isArray(keywords) && keywords.length > 0) || is_preview ? (
                    <div className="keywords-text">
                      {translatedResponse && !is_preview ? (
                        Array.isArray(keywords) && keywords.length > 0 ? (
                          <>
                            <span>{translatedResponse.step3_header}</span>
                          </>
                        ) : (
                          <span>Step 2: {t('gmb:REVIEWS.CUSHION.FEEDBACK_MSG')}</span>
                        )
                      ) : (
                        Array.isArray(keywords) && keywords.length > 0 ? (
                          <span>Step 3: {t('gmb:REVIEWS.CUSHION.FEEDBACK_MSG')}</span>
                        ) : (
                          <span>Step 2: {t('gmb:REVIEWS.CUSHION.FEEDBACK_MSG')}</span>
                        )
                      )}
                    </div>
                  ) : (
                    <div className="keywords-text">
                      {translatedResponse && !is_preview ? (
                        Array.isArray(keywords) && keywords.length > 0 ? (
                          <>
                            <span>{translatedResponse.step3_header}</span>
                          </>
                        ) : (
                          <span>Step 2: {t('gmb:REVIEWS.CUSHION.FEEDBACK_MSG')}</span>
                        )
                      ) : (
                        Array.isArray(keywords) && keywords.length > 0 ? (
                          <span>Step 3: {t('gmb:REVIEWS.CUSHION.FEEDBACK_MSG')}</span>
                        ) : (
                          <span>Step 2: {t('gmb:REVIEWS.CUSHION.FEEDBACK_MSG')}</span>
                        )
                      )}
                    </div>
                  )}
                  <div className="generate-caption">
                    {is_preview ? (
                      <CButton onClick={handleGenerateCaption} disabled>
                        {translatedResponse && !is_preview
                          ? translatedResponse.auto_generate_button_text
                          : t('gmb:REVIEWS.CUSHION.REVIEW_AUTO_CREATE')}
                      </CButton>
                    ) : (
                      shouldShowGenerateCaptionButton && (
                        <CButton
                          onClick={handleGenerateCaption}
                          disabled={selectedKeywords.length === 0 || isLoading}
                        >
                          {translatedResponse && !is_preview
                            ? translatedResponse.auto_generate_button_text
                            : t('gmb:REVIEWS.CUSHION.REVIEW_AUTO_CREATE')}
                        </CButton>
                      )
                    )}
                  </div>

                  <textarea
                    value={review_comment}
                    onChange={handleCommentChange}
                    placeholder={
                      translatedResponse && !is_preview
                        ? translatedResponse.comment_placeholder
                        : t('gmb:REVIEWS.CUSHION.COMMENT_OPTIONAL')
                    }
                    style={{ height: 'auto' }}
                    onInput={(e) => handleCommentChange(e)}
                    disabled={!isRatingSelected}
                  />
                  <div className="preview-actions">
                    {is_preview ? (
                      <CButton type="submit" onClick={handleSubmit} disabled>
                        {translatedResponse && !is_preview
                          ? translatedResponse.submit_button_text
                          //
                          : t('gmb:REVIEWS.CUSHION.SUBMIT')}
                      </CButton>

                    ) : (
                      <CButton
                        type="submit"
                        onClick={handleSubmitRatings}
                        disabled={!isRatingSelected}
                      >
                        {translatedResponse && !is_preview
                          ? translatedResponse.submit_button_text
                          : t('gmb:REVIEWS.CUSHION.SUBMIT')}
                      </CButton>
                    )}
                  </div>

                </div>

                {!is_preview && (
                  <CRow>
                    <select
                      value={selectedLanguage}
                      onChange={handleLanguageChange}
                      className="cushion-lang-selection"
                    >

                      <option value="ja">日本語</option>
                      <option value="en">English</option>
                      <option value="zh-CN">中國人</option>
                      <option value="ko">한국어</option>
                      <option value="th">แบบไทย</option>
                      <option value="es">Español</option>
                    </select>
                  </CRow>
                )}</>)
          }
        </>
      )}
    </>
  );
}

CushionView.propTypes = {
  previewLogo: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  location_id: PropTypes.number,
  keywords: PropTypes.arrayOf(PropTypes.string),
  is_preview: PropTypes.bool,
  is_encrypted: PropTypes.string,
  handleLogoChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleReviewSubmitted: PropTypes.func,
  business_category_name: PropTypes.string,
};

CushionView.defaultProps = {
  previewLogo: '',
  title: '',
  description: '',
  location_id: 0,
  keywords: '',
  is_preview: false,
  is_encrypted: 'false',
  handleLogoChange: () => { },
  handleSubmit: () => { },
  handleReviewSubmitted: () => { },
  business_category_name: '',
};

export default CushionView;
