/* eslint-disable camelcase */
/* eslint-disable no-return-await */

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import CushionView from './cushion';
import Loading from '../../../../commons/components/Loading';
import {
  REVIEW_COLLECTION,
  CUSHION_REDIRECT_GBP,
} from '../../../../commons/constants/url';
import './cushion.scss';
import './cushionlayout.scss';

function CushionLayout({ is_encrypted }) {
  const { t } = useTranslation(['gmb']);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [cushionData, setCushionData] = useState({});
  const [reviewSubmitted, setReviewSubmitted] = useState(false);

  // console.log('is_encrypted', is_encrypted);

  useEffect(() => {
    axios
      .get(`${REVIEW_COLLECTION}/${id}?is_encrypted=${is_encrypted}`)
      .then((response) => {
        const data = response?.data;
        if (typeof data?.result?.keywords === 'string') {
          data.result.keywords = JSON.parse(data.result.keywords);
        }
        setCushionData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, [id]);

  const handleParentReviewSubmitted = (value) => {
    setReviewSubmitted(value);
  };

  useEffect(() => {
    document.title = '口コミを投稿する';
  }, []);

  const redirectGBP = async () => {
    try {
      const url = `${CUSHION_REDIRECT_GBP}/${id}`;
      const response = await axios.get(url);
      const redirectUrl = response.data;
      window.location.href = redirectUrl;
    } catch (error) {
      console.error('Error calling API:', error);
    }
  };

  return (
    <div>
      <Loading loading={loading} />
      {reviewSubmitted ? (
        <div className="centered-container">
          <div>{t('gmb:REVIEWS.CUSHION.REVIEW_MSG')}</div>
        </div>
      ) : (
        <div>
          {cushionData && cushionData?.success === true ? (
            <>
              <div className="cushion-layout-container">
                <CushionView
                  previewLogo={cushionData?.result?.logo_url}
                  title={cushionData?.result?.title}
                  description={cushionData?.result?.description}
                  is_preview={0}
                  keywords={cushionData?.result?.keywords}
                  location_id={cushionData?.result?.location_id}
                  handleReviewSubmitted={handleParentReviewSubmitted}
                  is_encrypted={is_encrypted}
                  business_category_name={
                    cushionData?.result?.business_category_name
                  }
                />
              </div>
            </>
          ) : (
            <div className="cushion-redirect-wrapper">
              <img
                src="https://igmb-api-files.s3.ap-northeast-1.amazonaws.com/files/uploaded/development/review_collections/review_collection_default_image_2.jpg"
                alt="Defaulf_Image_for_PC"
              />
              <Button
                className="cushion-redirect-btn"
                variant="text"
                onClick={redirectGBP}
              >
                Googleマップはこちら
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

CushionLayout.propTypes = {
  is_encrypted: PropTypes.string,
};

CushionLayout.defaultProps = {
  is_encrypted: 'false',
};

export default CushionLayout;
